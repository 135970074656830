import { random } from 'lodash-es'

/**
 *
 * @param { string | undefined } ua
 * @returns { string | undefined }
 */
export function userAgent(ua) {
  let userAgent = ua ?? window?.navigator?.userAgent

  // WKWebView by default doesn't include this Safari piece, but Google looks for it during their authentication
  if (userAgent?.match(/iphone/i)) {
    // some sites break without a version part
    if (!userAgent.match(/ version\//i))
      userAgent = `${userAgent} Version/${random(16, 17)}.1.1`
    userAgent = `${userAgent} Safari/${random(536, 605)}.1`
  }

  // Android WebView by default includes "wv" and "Version", but Google does not like that
  // Downgrading Android version if it is above 12 as it currently does not work
  if (userAgent?.match(/android/i)) {
    const androidUaVersion = userAgent.match(/android\s([\d.]+)/i)?.[1]
    const androidMainVersion = androidUaVersion?.split('.')?.[0]

    const chromeUaVersion = `${random(110, 116)}.0.0.0`
    const chromeVersion = userAgent.match(/Chrome\/(\d+)/i)?.[1]

    if (parseInt(chromeVersion) > 116) {
      userAgent = userAgent.replace(/Chrome\/\d+\.\d+\.\d+\.\d+/i, '')
      userAgent = `${userAgent} Chrome/${chromeUaVersion}`
    }

    if (parseInt(androidMainVersion) > 12) {
      userAgent = userAgent.replace(
        /Android \d+(\.\d+)?(\.\d+)?/i,
        `Android ${random(10, 12)}`
      )
    }

    userAgent = userAgent.replace(/wv/i, '').replace(/ version\/\S+/i, '')
  }

  return userAgent
}
