export const manualDepositInstructions = {
  accountInformationHiddenAriaLabel:
    'Información de la cuenta; escondido para su protección.',
  accountInformationVisibleAriaLabel:
    'Información de la cuenta; visible y se puede copiar a su portapapeles.',
  accountInformationVisibleAriaLive:
    'La información de la cuenta ahora es visible y se puede copiar.',
  accountNumber: 'Número de cuenta',
  copied: 'Copiado',
  copyAccountNumber: 'Copiar número de cuenta.',
  copyRoutingNumber: 'Copie el número de ruta.',
  disabledCopyButtonAriaLabel:
    'Continúe con el botón Mostrar números de cuenta para copiar la información de la cuenta.',
  routingNumber: 'Número de ruta',
  showAccountInformation: 'Mostrar números de cuenta',
  updateYourDeposit: ({ name }) => `Actualice su depósito en ${name}`
}
