<template>
  <BadgeBase :backgroundColor="badgeBackgroundColor">
    <IconInformation :size="18" :stroke="iconColor" />
  </BadgeBase>
</template>

<script>
import BadgeBase from '@/components/Badge/BadgeBase.vue'
import IconInformation from '@/components/Icons/Atomic/IconInformation.vue'
import { getGlobalCSSValue } from '@/util/general'

export default {
  name: 'BadgeInfo',
  components: {
    BadgeBase,
    IconInformation
  },
  computed: {
    badgeBackgroundColor() {
      return getGlobalCSSValue('--gray-600')
    },
    iconColor() {
      return getGlobalCSSValue('--white')
    }
  }
}
</script>
