export const manualFallback = {
  otherWays: 'Configura el depósito directo manualmente',
  prefilledMethod: 'Formulario pre-rellenado',
  prefilledDescription: `Obtén un formulario pre-rellenado con la información que necesitarás para configurar el depósito directo con su empleador.`,
  copyMethod: 'Copiar detalles de la cuenta',
  copyDescription: `Proporciona los detalles de su cuenta a su empleador para configurar el depósito directo.`,
  setUpManuallyButton: 'Configurar manualmente',
  showAccountNumber: 'Mostrar número de cuenta',
  whichMethodPreferred: '¿Qué método de configuración prefieres?',
  whichMethodPreferredDescription: `Utiliza uno de los siguientes métodos para configurar el depósito directo con su empleador.`,
  createPrefilledForm: `Creemos su formulario pre-rellenado`,
  fullName: 'Nombre completo',
  changeTimeline:
    'Los cambios pueden tardar de 1 a 2 ciclos de pago en surtir efecto.',
  formIsReady: `¡Su formulario de depósito directo está listo!`,
  generatingForm: 'Espere mientras generamos su formulario...',
  name: 'Nombre',
  amount: 'Cantidad del depósito',
  account: 'Cuenta',
  routingNumber: 'Número de ruta',
  accountNumber: 'Número de cuenta',
  downloadForm: 'Descargar formulario',
  editDepositAmount: 'Editar cantidad del depósito',
  useAccountInfoTitle:
    'Configura el depósito directo usando los detalles de su cuenta.',
  useAccountInfoDescription:
    'Sigue el proceso de su empleador para configurar el depósito directo usando esta información.',
  niceNextUp: '¡Genial! Ya casi terminas.',
  giveToHR: `Ahora sigue el proceso de su empleador para configurar el depósito directo usando esta información.`,
  entirePaycheck: 'Cheque de pago completo',
  setUpManuallyHeading: 'Configura el depósito directo manualmente',
  setUpManuallySubheading:
    'Obtén un formulario pre-rellenado o copia los detalles de su cuenta.'
}
