import { TASK_FAIL_REASON } from '@/util/constants'

export const errorMessage = {
  failedRequest: 'Bad request',
  expiredToken: 'Expired token',
  expiredSession: 'Expired session',
  expiredSessionDescription:
    'Your session has expired. Please exit and try again.',
  invalidToken: 'You are unauthorized to view this page.',
  taskUnauthorized: 'Task unauthorized',
  failedConfig: 'Invalid configuration.',
  reconnecting: 'Reconnecting',
  connectionLost: 'Connection lost',
  [TASK_FAIL_REASON.BAD_CREDENTIALS]: {
    title: 'Invalid credentials',
    message: 'Either your username or password was incorrect. Please try again.'
  },
  [TASK_FAIL_REASON.ACCOUNT_LOCKOUT]: {
    title: 'Account locked',
    message: 'You have too many failed sign in attempts.'
  },
  [TASK_FAIL_REASON.ACCOUNT_SETUP_INCOMPLETE]: {
    title: 'Account setup incomplete',
    message:
      "Before your payroll account can be connected, you'll need to sign in and complete employee onboarding."
  },
  [TASK_FAIL_REASON.CONNECTION_ERROR]: {
    title: 'Problem connecting',
    message: 'Sometimes there are network failures. Trying again may help.'
  },
  [TASK_FAIL_REASON.SESSION_TIMEOUT]: {
    title: 'Session expired',
    message: 'Trying again will likely fix the issue.'
  },
  [TASK_FAIL_REASON.PRODUCT_NOT_SUPPORTED]: {
    title: 'Unsupported update',
    message:
      'Please make sure your account is fully active and your employer has not restricted access.'
  },
  [TASK_FAIL_REASON.DATA_VALIDATION_FAILED]: {
    title: `We couldn't locate your info`,
    message:
      'We connected to your account but couldn’t find all of the required data.'
  },
  [TASK_FAIL_REASON.NO_DATA_FOUND]: {
    title: `We couldn’t confirm your account`,
    message:
      'Your account’s employment data was missing. Are you sure this is your current account?'
  },
  [TASK_FAIL_REASON.UNKNOWN_FAILURE]: {
    title: 'Something went wrong',
    message: 'We ran into an unexpected error. Please try again.'
  },
  [TASK_FAIL_REASON.WORK_STATUS_TERMINATED]: {
    title: 'Wrong account',
    message:
      'If you’re trying to sign in with your work email, try your personal email instead or reset your password.'
  },
  [TASK_FAIL_REASON.ACCOUNT_UNUSABLE]: {
    title: 'Bank account rejected',
    message: 'Your payroll account rejected adding this new bank account.'
  },
  [TASK_FAIL_REASON.DISTRIBUTION_NOT_SUPPORTED]: {
    title: 'Deposit amount rejected',
    message:
      'Your account does not support the requested deposit amount or there are conflicting allocation amounts.'
  },
  [TASK_FAIL_REASON.ENROLLED_IN_PAYCARD]: {
    title: 'Sorry, we currently don’t support pay cards',
    message:
      'It looks like your paycard enrollment prevents you from being paid through direct deposit to a bank account.'
  },
  [TASK_FAIL_REASON.EXPIRED]: {
    title: 'Expired password',
    message: 'Create a new password to continue.'
  },
  [TASK_FAIL_REASON.ROUTING_NUMBER_NOT_SUPPORTED]: {
    title: 'Rejected routing number',
    message:
      'The system rejected your accounts routing number. You may need to contact them to proceed.'
  },
  [TASK_FAIL_REASON.SYSTEM_UNAVAILABLE]: {
    title: 'System unavailable',
    message: 'Please try again later.'
  },
  [TASK_FAIL_REASON.TRANSACTION_PENDING]: {
    title: 'Update pending',
    message:
      'You have an update to your account that is still processing. Please try again later.'
  },
  [TASK_FAIL_REASON.USER_DATA_REJECTED]: {
    title: 'Thanks for letting us know',
    message:
      'As the data appears to be inaccurate it will not be shared. You can try another employer login and choose another option for confirming your income.'
  },
  failedWithholdNotSupported:
    'You must provide a linked account to use the withhold product.'
}
