<template>
  <div class="icon-failed-wrapper mx-auto mb-3">
    <IconExclamation :size="24" :stroke="iconFailedStroke" aria-hidden="true" />
  </div>
  <div class="text-center mb-3" :lang="langAttribute">
    <h2>
      {{ failureMessaging.title }}
    </h2>
    <p>
      {{ failureMessaging.message }}
    </p>
  </div>
  <div class="action-list">
    <BaseButton
      btn-style="solid"
      data-test-id="try-again"
      :text="phrases.shared.tryAgain"
      customer-branded
      @handleClick="handleClickTryAgain"
    />
    <BaseButton
      btn-style="hollow"
      data-test-id="remove"
      :text="phrases.shared.removeSelection"
      @handleClick="handleClickRemove"
    />
    <TextButton
      @handleClick="handleClickClose"
      :text="phrases.shared.close"
      data-test-id="close"
    />
  </div>
</template>

<script>
import BaseButton from '@/components/Form/BaseButton.vue'
import TextButton from '@/components/Form/TextButton.vue'
import IconExclamation from '@/components/Icons/Atomic/IconExclamation.vue'
import { generateTaskFailureMessaging } from '@/util/messaging'
import { mapGetters } from 'vuex'
import { getGlobalCSSValue } from '@/util/general'
import { PAGES } from '@/util/constants'
import { createSelectionAnalyticsPayload } from '@/util/pay-link'

export default {
  name: 'SelectionDetailModalContentFailed',
  components: {
    BaseButton,
    IconExclamation,
    TextButton
  },
  emits: ['close', 'remove-selection', 'try-again'],
  props: {
    selection: Object,
    switchTask: Object
  },
  methods: {
    handleClickRemove() {
      this.$analytics.track({
        event: `Clicked Remove Selection From ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection)
      })
      this.$emit('remove-selection')
    },
    handleClickTryAgain() {
      this.$analytics.track({
        event: `Clicked Try Again From ${PAGES.SELECTION_DETAILS}`,
        payload: createSelectionAnalyticsPayload(this.selection)
      })
      this.$emit('try-again')
    },
    handleClickClose() {
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters('theme', ['useDarkMode']),
    ...mapGetters('i18n', ['langAttribute']),
    failureMessaging() {
      return generateTaskFailureMessaging({
        store: this.$store,
        failReason: this.switchTask.failReason,
        customFailureTitle: this.switchTask.customFailureTitle,
        customFailureMessage: this.switchTask.customFailureMessage
      })
    },
    iconFailedStroke() {
      return getGlobalCSSValue(this.useDarkMode ? '--error-300' : '--error-500')
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-failed-wrapper {
  height: 24px;
  width: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--error-100);

  .dark & {
    background: var(--error-700);
  }
}
</style>
