<template>
  <Card
    is="div"
    class="mb-5"
    :aria-label="phrases.shared.inProgressPaymentMethod"
    :aria-description="
      phrases.shared.paymentMethodAriaDescription({
        paymentMethod: switchTask.settings.switch.paymentMethod
      })
    "
  >
    <PaymentMethodOptionContent
      aria-hidden="true"
      :payment-method="switchTask.settings.switch.paymentMethod"
    />
  </Card>
  <TextButton
    data-test-id="close"
    :text="phrases.shared.close"
    @handleClick="handleClickClose"
  />
</template>

<script>
import TextButton from '@/components/Form/TextButton.vue'
import Card from '@/components/Shared/Card.vue'
import PaymentMethodOptionContent from '@/components/Switch/PaymentMethodOptionContent.vue'

export default {
  name: 'SelectionDetailModalContentInProgress',
  components: {
    TextButton,
    Card,
    PaymentMethodOptionContent
  },
  emits: ['close'],
  props: {
    selection: Object,
    switchTask: Object
  },
  methods: {
    handleClickClose() {
      this.$emit('close')
    }
  }
}
</script>
