export const taskProgress = {
  noNeedToHangAround: 'No need to hang around. We will take it from here.',
  progressTitleDeposit: `You're almost there. We're updating your direct deposit...`,
  progressTitleVerify: `You're almost there. Linking your payroll...`,
  progressTitleWithhold: `You're almost there. We're updating your withholdings...`,
  ariaSuccessPrompt: ', finished successfully.',
  ariaWaitPrompt: ', please wait.',
  messageAverage: 'On average, this takes',
  seconds: 'seconds',
  minutes: 'minutes',
  estimatedTimeRemaining: 'Estimated time remaining is',
  tax: {
    title: `We're gathering your tax documents...`
  }
}
