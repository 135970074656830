import {
  ROUTES,
  MODAL_VIEW,
  ERROR_CODES,
  PRODUCTS,
  SCOPES,
  SESSION_CONTEXTS,
  DEEPLINK_STEPS
} from '@/util/constants'
import { navigateToCompanyLogin, navigateToDeeplink } from '@/util/navigation'
import {
  convertToTaskWorkflowStructure,
  taskGenerator
} from '@/util/task-workflow'
import { convertToThemeObject } from '@/util/theme'
import { loadI18n } from '@/languages/main'
import { triggerAnalyticEvent } from '@/util/analytics'
import { determineInitialRoute as determineInitialPayLinkRoute } from '@/util/pay-link'

export function parseConfig(config) {
  const parsedConfig = JSON.parse(atob(config))

  // Copy configuration values from the conversion token into the primary config
  if (parsedConfig.conversionToken) {
    const parsedConversionToken = JSON.parse(atob(parsedConfig.conversionToken))

    return {
      conversionTokenType: parsedConversionToken.type,
      ...parsedConfig,
      ...parsedConversionToken.transactConfig
    }
  } else {
    return parsedConfig
  }
}

export function initializeStore({ config, store }) {
  if (config.token) store.dispatch('main/updatePublicToken', config.token)
  if (config.inSdk === false || config.inSDK === false)
    store.dispatch('main/updateInSdk', config.inSdk || config.inSDK)
  if (config.demo) store.dispatch('demo/updateDemo', config.demo)
  if (config.safeMode) store.dispatch('main/updateSafeMode', config.safeMode)
  if (config.linkedAccount) {
    store.dispatch('main/updateLinkedAccount', config.linkedAccount)
  }
  if (config.taskIdToConvert) {
    store.dispatch('task/updateTaskIdToConvert', config.taskIdToConvert)
  }
  if (config.testMode) store.dispatch('main/updateTestMode', config.testMode)
  if (config.theme?.dark) {
    store.dispatch('theme/updateDarkMode', config.theme.dark)
  }
  if (config.color) {
    const themeObject = { brandColor: config.color }
    const theme = convertToThemeObject({ theme: themeObject })
    store.dispatch('theme/updateTheme', theme)
  }
  if (config.deeplink) {
    store.dispatch('main/updateDeeplink', config.deeplink)
  }
  if (config.sessionContext) {
    store.dispatch('main/updateSessionContext', config.sessionContext)

    /**
     * The TrueAuth app will not track its own events
     * Instead it will send them to the original session
     */
    if (config.sessionContext === SESSION_CONTEXTS.TRUE_AUTH_APP) {
      store.dispatch('analytics/updateTrackAnalyticsEvents', false)
    }
  }
  if (config.platform) {
    store.dispatch('main/updatePlatform', config.platform)
  }
  if (config.useSimulator) {
    store.dispatch('main/updateUseSimulator', config.useSimulator)
  }
  if (config.handoff) store.dispatch('main/updateHandoff', config.handoff)

  // Change to SDK requires some backwards compatibility
  if (config.publicToken)
    store.dispatch('main/updatePublicToken', config.publicToken)
  if (config.theme) {
    const theme = convertToThemeObject({ theme: config.theme })
    store.dispatch('theme/updateTheme', theme)
  }

  if (config.tasks) {
    store.dispatch(
      'taskWorkflow/updateTaskWorkflowTasks',
      taskGenerator({ tasks: config.tasks, store })
    )
  }

  if (config.customer) {
    store.dispatch('user/updateCustomerOverrides', config.customer)
  }

  // Set initial distribution settings if needed
  if (_checkForDistributionConfig(config)) {
    store.dispatch(
      'distribution/updateDistributionSettings',
      _getDistributionConfig(config)
    )
  }
  // Set initial withhold settings if needed
  if (config.withholding) {
    store.dispatch('withholding/updateWithholding', config.withholding)
  }
  if (config.requireTerms) {
    store.dispatch('main/updateInitializeTerms', {
      isRequiredToStart: config.requireTerms
    })
  }
  if (config.search) {
    store.dispatch('search/updateEnforcedSearchParams', config.search)
  }
  if (config.metadata) {
    store.dispatch('main/updateUserMetadata', config.metadata)
  }
  if (config.experiments) {
    store.dispatch('main/updateExperiments', config.experiments)
  }

  // Support old sdk parameters until Andrea says we can axe them
  if (config.product) {
    convertToTaskWorkflowStructure({ product: config.product, store })
  }

  // Some customers (Netspend) are still sending us `employer-link` because their integration is wrong, so fallback to user-link
  if (config.scope && config.scope !== 'employer-link') {
    store.dispatch('main/updateScope', config.scope)
  } else {
    store.dispatch('main/updateScope', SCOPES.USER_LINK)
  }

  initI18n({ store })

  store.dispatch('main/updateAppIsReady', true)
}

export function initializeFirstView({ store, router }) {
  if (store.getters['main/isScopePayLink']) {
    _initializePayLinkFirstRoute({ store, router })
  } else if (
    store.getters['user/incomeSourcesOfTypeChecking'].length &&
    store.getters['taskWorkflow/productsIncludeDeposit'] &&
    store.getters['user/showDetectedIncomeSourcesFlow']
  ) {
    _initializeDepositPreSelectedCompanies({ store, router })
  } else if (store.state.main.linkedAccountId) {
    _initializeLinkedAccount({ store, router })
  } else if (
    _taskListNotUsingTax({ store }) &&
    // If task history is turned on while trying to generate a deposit pdf,
    // the task history checks superseeds the deeplink to generate the pdf.
    // Adding this check to make sure the task history is not initialized
    // when trying to generate a deposit pdf.
    store.state.main.deeplink.step !== DEEPLINK_STEPS.GENERATE_DEPOSIT_PDF
  ) {
    _initializeTaskHistory({ router })
  } else if (
    store.state.main.deeplink.step &&
    store.getters['user/userHasMultipleAccounts'] &&
    store.state.main.deeplink.step !== DEEPLINK_STEPS.GENERATE_DEPOSIT_PDF
  ) {
    _initializeMultipleAccounts({ store, router })
  } else if (store.state.main.deeplink.step) {
    navigateToDeeplink({ store, router })
  } else {
    _initializeWelcome({ store, router })
  }
}

async function _initializeWelcome({ store, router }) {
  // If user tries to use the withold product without a linkedAccount
  // we need to show an error
  if (_isUsingWithholdProductWithoutLinkedAccount({ store })) {
    _triggerWithholdError({ store })
  } else {
    triggerAnalyticEvent({ eventName: 'Initialized Welcome Page' })
    router.push({ name: ROUTES.WELCOME })
  }
}

function _initializePayLinkFirstRoute({ store, router }) {
  router.push({
    name: determineInitialPayLinkRoute({
      deeplink: store.state.main.deeplink,
      userStatus: store.getters['payLink/userStatus']
    })
  })
}

function _initializeDepositPreSelectedCompanies({ router }) {
  router.push({ name: ROUTES.DEPOSIT_PRESELECTED_COMPANIES })
}

function _initializeTaskHistory({ router }) {
  router.push({ name: ROUTES.TASK_HISTORY })
}

function _initializeMultipleAccounts({ store, router }) {
  if (store.getters['experiment/alwaysShowWelcomeScreen']) {
    router.push({ name: ROUTES.WELCOME })
  } else {
    router.push({ name: ROUTES.ACCOUNT })
  }
}

async function _initializeLinkedAccount({ store, router }) {
  const selectedLinkedAccount =
    store.state.taskHistory.linkedAccountList.filter((linkedAccount) => {
      return linkedAccount._id === store.state.main.linkedAccountId
    })

  triggerAnalyticEvent({ eventName: 'Initialized Linked Account' })

  await navigateToCompanyLogin({
    companyId: selectedLinkedAccount[0].company._id,
    store,
    router
  })
}

function _isUsingWithholdProductWithoutLinkedAccount({ store }) {
  return (
    // TODO lodash function isEmpty
    store.state.withholding &&
    Object.keys(store.state.withholding.withholding).length >= 1 &&
    !store.state.main.linkedAccountId
  )
}

function _triggerWithholdError({ store }) {
  store.dispatch('main/updateGlobalErrorStatus', {
    code: ERROR_CODES.GLOBAL_WITHHOLD_NOT_SUPPORTED
  })
  store.dispatch('modal/openModal', { view: MODAL_VIEW.ERROR })
}

function _getDepositTask(config) {
  if (config.tasks) {
    const isDepositTask = config.tasks.find((task) =>
      _taskContainsDeposit(task)
    )
    return isDepositTask
  }
}

function _checkForDistributionConfig(config) {
  return config.distribution || _isDepositAndContainsDistribution(config)
    ? true
    : false
}

function _isDepositAndContainsDistribution(config) {
  if (config.tasks) {
    return config.tasks.some(
      (task) => _taskContainsDeposit(task) && task.distribution
    )
  } else {
    return false
  }
}

function _getDistributionConfig(config) {
  const depositTask = _getDepositTask(config)
  return config.distribution || depositTask.distribution
}

function _taskListNotUsingTax({ store }) {
  return (
    store.state.taskHistory.taskList.length > 0 &&
    !store.getters['taskWorkflow/taskWorkflowTypeIsTax']
  )
}

export function initI18n({ store }) {
  store.dispatch(
    'i18n/updateLanguage',
    window.atomicStorage.getItem('language')
  )
  store.dispatch(
    'i18n/updateDefaultPhrases',
    loadI18n({ scope: store.state.main.scope })
  )
}

function _taskContainsDeposit(task) {
  return (
    task.product === PRODUCTS.DEPOSIT || task.operation === PRODUCTS.DEPOSIT
  )
}
