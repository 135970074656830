export const taskCompleted = {
  allDoneHere: '¡Todo listo aquí!',
  continueBackOnOtherDevice: 'Continúa en su navegador web.',
  messageDistributionUpdate:
    'Los cambios pueden tardar de 1 a 2 ciclos de pago en surtir efecto.',
  linkAnotherGigAccount: 'Conectar otra cuenta de trabajo temporal',
  linkAnotherAccount: 'Configurar otra cuenta',
  messageOnlyAuthCompletedTitle: `¡Buen trabajo! Ahora es nuestro turno`,
  messageOnlyAuthCompletedDeposit: `Solo necesitamos un momento para terminar de actualizar su depósito directo, pero no es necesario que le quedes esperando.`,
  messageOnlyAuthCompletedVerify: `Solo necesitamos un momento para terminar de enlazar su nómina, pero no es necesario que le quedes esperando.`,
  messageOnlyAuthCompletedWithhold: `Solo necesitamos un momento para terminar de actualizar sus retenciones, pero no es necesario que le quedes esperando.`,
  signInto: 'Inicia sesión en',
  tryAgain: 'Intentar de nuevo',
  messageLong: 'La autenticación está tomando más tiempo de lo esperado.',
  keepWaiting: 'Sigue esperando',
  messageWait:
    'Si lo deseas, puedes irte y le actualizaremos sobre el progreso.',
  messageContinueProcess:
    'Continuaremos procesando su solicitud y le informaremos sobre el resultado.',
  messageAllSet: '¡Todo listo!',
  gigSuccessTitle: ({ customerName, connectorName }) =>
    `Su depósito directo en ${connectorName} ahora está actualizado a ${customerName}`,
  loginWithYourCredentials: 'Inicia sesión con sus credenciales',
  messageNotifiedComplete:
    'Se le notificará cuando el proceso esté completo, o si se necesita su ayuda',
  messageDepositTaskCompleted: ({ customerName }) =>
    `Su depósito directo ahora está actualizado a ${customerName}.`,
  messageReadDataTaskCompleted: ({ customerName }) =>
    `Su nómina ha sido compartida de forma segura con ${customerName}.`,
  messageAuthenticationSuccessful: 'Autenticación exitosa.',
  messageCompleteDescriptionDave: `Buen trabajo al iniciar sesión. Ahora es nuestro turno - actualizaremos su depósito directo en unos momentos. Por favor, ten en cuenta que esto puede tardar hasta 5 minutos.`,
  tax: {
    completed: {
      title: `¡Todo listo!`,
      description: `Hemos recopilado su información necesaria para la declaración de impuestos.`,
      actionItems: {
        connectAnother: 'Conectar otra cuenta'
      }
    }
  }
}
