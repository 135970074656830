export const taskProgress = {
  noNeedToHangAround:
    'No es necesario que le quedes. Nosotros nos encargamos de aquí en adelante.',
  progressTitleDeposit: `Ya casi terminas. Estamos procesando la actualización de su depósito...`,
  progressTitleVerify: `Ya casi terminas. Enlazando su nómina...`,
  progressTitleWithhold: `Ya casi terminas. Estamos procesando sus retenciones...`,
  ariaSuccessPrompt: ', finalizado con éxito.',
  ariaWaitPrompt: ', por favor espera.',
  messageAverage: 'En promedio, esto tarda',
  seconds: 'segundos',
  minutes: 'minutos',
  estimatedTimeRemaining: 'El tiempo estimado restante es',
  tax: {
    title: `Estamos recopilando sus documentos fiscales...`
  }
}
