<template>
  <ModalContent>
    <CloseButton v-if="inSdk" class="close-button" @close="close" />
    <ModalBody> <NoInternetConnection /></ModalBody>
  </ModalContent>
</template>

<script>
import CloseButton from '@/components/Shared/CloseButton.vue'
import ModalContent from '@/components/Modal/ModalContent.vue'
import NoInternetConnection from '@/components/Connection/NoInternetConnection.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import { appClose } from '@/util/app-event'
import { SDK_EVENT_TYPES, PAGES } from '@/util/constants'
import { mapState } from 'vuex'

export default {
  name: 'OfflineModal',
  components: {
    CloseButton,
    ModalBody,
    NoInternetConnection,
    ModalContent
  },
  computed: {
    ...mapState('main', ['inSdk']),
    ...mapState('network', ['isOnline'])
  },
  created() {
    this.$analytics.track({
      event: `Viewed ${PAGES.NO_CONNECTION}`
    })
  },
  methods: {
    close() {
      appClose({ store: this.$store, eventType: SDK_EVENT_TYPES.CLOSE })

      this.$analytics.track({
        event: 'Clicked Close',
        payload: { exitScreen: this.$route.path, isOnline: this.isOnline }
      })
    }
  }
}
</script>

<style scoped>
.close-button {
  /**
  * This puts the close button in the same place as other screens
  * and allows the disconnected state UI to be centered on both axes.
  */
  position: absolute;
  right: 24px;
  top: 10px;
}
</style>
