export const AUTHENTICATION_STATES = {
  ADP_COAUTH_INTERSTITIAL: 'adpCoAuthInterstitial',
  BANK_INTERSTITIAL: 'bankInterstitial',
  CONFIGURABLE_CONNECTOR_NOT_FOUND: 'configurableConnectorNotFound',
  DETERMINING_AUTHENTICATION_METHOD: 'determiningAuthenticationMethod',
  GETTING_HELP: 'gettingHelp',
  IDLE: 'idle',
  INITIALIZING: 'initializing',
  LOGGING_IN: 'loggingIn',
  PROVIDING_CONFIGURABLE_CONNECTOR_ID: 'providingConfigurableConnectorId',
  RECOVERING_LOGIN: 'recoveringLogin',
  REVIEWING_CONFIGURABLE_CONNECTOR: 'reviewingConfigurableConnector',
  SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD:
    'searchingConfigurableConnectorTypeahead',
  SELECTING_CO_AUTH_CONNECTOR: 'selectingCoAuthConnector',
  SELECTING_LOGIN_METHOD: 'selectingLoginMethod',
  SELECTING_PAYROLL_PROVIDER: 'selectingPayrollProvider',
  SMART_AUTH_DATA_REQUEST_GETTING_HELP: 'smartAuthDataRequestGettingHelp',
  SMART_AUTH_DATA_REQUEST: 'smartAuthDataRequest',
  SMART_AUTH_FINISHED: 'smartAuthFinished',
  SOCIAL_SECURITY_INTERSTITIAL: 'socialSecurityInterstitial',
  SOCIAL_SECURITY_LOGIN_OPTIONS: 'socialSecurityLoginOptions',
  SOCIAL_SECURITY_SSI: 'socialSecuritySsi',
  UPLINK_AUTHENTICATION: 'uplinkAuthentication',
  UPLINK_INTERSTITIAL: 'uplinkInterstitial'
}
