<template>
  <span
    class="terms-conditions-actions"
    :class="{
      dark: useDarkMode,
      'paired-with-pba': pairedWithPba,
      'paypal-styles': paypalRequired
    }"
  >
    <span v-if="separated">
      <span v-if="pairedWithPba"
        >{{ phrases.legal.termsConditionsSee }}&nbsp;</span
      >
      <button
        data-test-id="privacy"
        class="btn content-only"
        v-store-focused-element:click="() => showLegal('privacy-policy')"
        :style="disclaimerStyles"
        id="poweredByAtomic-showLegalButton"
      >
        {{ phrases.legal.actionPrivacyPolicy }}
      </button>
      {{ phrases.legal.termsConditionsAnd }}
      <button
        data-test-id="privacy"
        class="btn content-only"
        v-store-focused-element:click="showLegal"
        :style="disclaimerStyles"
        id="termsNoUnderline-showLegalButton"
      >
        {{ phrases.legal.actionTerms }}
      </button>
    </span>
    <span v-else>
      <button
        data-test-id="privacy"
        class="btn has-underline content-only"
        v-store-focused-element:click="showLegal"
        :style="disclaimerStyles"
        id="termsUnderlined-showLegalButton"
      >
        {{ phrases.legal.actionTermsOfUse }}
      </button>
    </span>
  </span>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { MODAL_VIEW } from '@/util/constants'
import { scrollToPrivacyPolicy } from '@/util/animation'
import { linkStyles } from '@/util/branding'
import { featureUniqueForPaypal } from '@/util/customization'

export default {
  name: 'LegalDisclaimerActions',
  props: {
    separated: Boolean,
    pairedWithPba: Boolean
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapGetters('theme', ['useDarkMode']),
    paypalRequired() {
      return featureUniqueForPaypal({ customer: this.userData.customer })
    },
    disclaimerStyles() {
      return linkStyles()
    }
  },
  methods: {
    ...mapActions('modal', ['openModal']),
    async showLegal(location) {
      await this.openModal({
        view: MODAL_VIEW.LEGAL
      })

      if (location === 'privacy-policy') {
        scrollToPrivacyPolicy()
      }
      this.$analytics.track({
        event: 'Clicked Terms Of Use Link From Powered By Atomic Footer'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.terms-conditions-actions {
  display: inline;
  font-size: 1.2rem;
  &.paired-with-pba {
    display: inline-block;
    position: absolute;
    margin-top: 2px;
    opacity: 0;
    z-index: -1;
    transform: translateY(5px);
    font-size: 1.4rem;
  }
  &.paypal-styles {
    color: #0c0c0d; //Paypal black/gray
    .btn,
    span {
      font-size: 1.4rem;

      &:hover,
      &:active {
        color: #0c0c0d !important; //Paypal black/gray
      }
      .dark & {
        &:hover,
        &:active {
          color: #0c0c0d !important; //Paypal black/gray
        }
      }
    }
  }
  .btn {
    font-size: 1.2rem;
    transition: opacity 250ms ease-in-out;
    &.has-underline {
      text-decoration: underline;
    }
    &:hover,
    &:active {
      color: var(--gray-900) !important;
    }
    .dark & {
      &:hover,
      &:active {
        color: var(--gray-300) !important;
      }
    }
  }
  &.dark {
    color: var(--gray-500);
  }
}
</style>
