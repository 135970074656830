import Analytics from '@/plugins/analytics'
import { MODAL_VIEW } from '@/util/constants'

export function listenForNetworkEvents({ store }) {
  const analyticsInstance = Analytics.get()

  window.addEventListener('offline', () => {
    if (store.state.modal.modalState.view === MODAL_VIEW.OFFLINE) {
      return
    }

    store.dispatch('network/updateIsOnline', false)

    analyticsInstance.track({
      event: 'Went offline',
      internal: true
    })

    store.dispatch('modal/openModal', { view: MODAL_VIEW.OFFLINE })

    const onlineListener = async () => {
      store.dispatch('network/updateIsOnline', true)

      await store.dispatch('modal/closeModal')

      analyticsInstance.track({
        event: 'Went online',
        internal: true
      })

      window.removeEventListener('online', onlineListener)
    }

    window.addEventListener('online', onlineListener)
  })
}
