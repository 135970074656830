<template>
  <div
    v-if="appIsReady"
    class="skeleton-template-wrap"
    :class="{ dark: useDarkMode }"
  >
    <TaskHistorySkeleton v-if="isReturnUser" />
    <PreselectedCompanySingleSkeleton v-else-if="isPreselectedCompanySingle" />
    <PreselectedCompanyMultipleSkeleton
      v-else-if="isPreselectedCompanyMultiple"
    />
    <AddCardSkeleton v-else-if="isAddCard" />
    <SearchGridSkeleton v-else-if="isSearchCompany || shouldShowForPaylink" />
    <SwitchWelcomeSkeleton v-else-if="isScopePayLink" />
    <WelcomeSkeleton v-else-if="isWelcome" />
    <SearchListSkeleton v-else-if="isSearchPayroll" />
    <ManualFallbackSkeleton v-else-if="isManualFallback" />
    <AdpSkeleton v-else-if="isLogin && isADP" />
    <LoginSkeleton v-else-if="isLogin" />
  </div>
</template>

<script>
import WelcomeSkeleton from '@/components/SkeletonLoader/WelcomeSkeleton.vue'
import SearchGridSkeleton from '@/components/SkeletonLoader/SearchGridSkeleton.vue'
import SearchListSkeleton from '@/components/SkeletonLoader/SearchListSkeleton.vue'
import LoginSkeleton from '@/components/SkeletonLoader/LoginSkeleton.vue'
import AdpSkeleton from '@/components/SkeletonLoader/AdpSkeleton.vue'
import TaskHistorySkeleton from '@/components/SkeletonLoader/TaskHistorySkeleton.vue'
import PreselectedCompanySingleSkeleton from '@/components/SkeletonLoader/PreselectedCompanySingleSkeleton.vue'
import PreselectedCompanyMultipleSkeleton from '@/components/SkeletonLoader/PreselectedCompanyMultipleSkeleton.vue'
import SwitchWelcomeSkeleton from '@/components/SkeletonLoader/SwitchWelcomeSkeleton.vue'
import AddCardSkeleton from '@/components/SkeletonLoader/AddCardSkeleton.vue'
import ManualFallbackSkeleton from '@/components/SkeletonLoader/ManualFallbackSkeleton.vue'
import { mapState, mapGetters } from 'vuex'
import { DEEPLINK_STEPS } from '@/util/constants'
import { isADPConnector } from '@/util/connector'
export default {
  name: 'SkeletonWrap',
  components: {
    AddCardSkeleton,
    WelcomeSkeleton,
    SearchGridSkeleton,
    SearchListSkeleton,
    LoginSkeleton,
    AdpSkeleton,
    TaskHistorySkeleton,
    SwitchWelcomeSkeleton,
    ManualFallbackSkeleton,
    PreselectedCompanySingleSkeleton,
    PreselectedCompanyMultipleSkeleton
  },
  computed: {
    ...mapState('main', ['appIsReady', 'deeplink']),
    ...mapGetters('main', ['isScopePayLink']),
    ...mapState('user', ['userData']),
    ...mapState('taskHistory', ['taskList']),
    ...mapState('company', ['connector']),
    ...mapGetters('theme', ['useDarkMode']),
    ...mapGetters('taskWorkflow', ['taskWorkflowTypeIsTax']),
    ...mapGetters('user', [
      'incomeSourcesOfTypeChecking',
      'showDetectedIncomeSourcesFlow'
    ]),
    deeplinkStep() {
      return this.deeplink?.step
    },
    isWelcome() {
      return !this.deeplinkStep
    },
    isSearchCompany() {
      return this.deeplinkStep === DEEPLINK_STEPS.SEARCH_COMPANY
    },
    shouldShowForPaylink() {
      const userCompanySelectionCount = window.atomicStorage.getItem(
        'userCompanySelectionCount'
      )
      return this.isScopePayLink && Number(userCompanySelectionCount)
    },
    isSearchPayroll() {
      return this.deeplinkStep === DEEPLINK_STEPS.SEARCH_PAYROLL
    },
    isLogin() {
      return this.deeplinkStep === DEEPLINK_STEPS.LOGIN_COMPANY
    },
    isAddCard() {
      return this.deeplinkStep === DEEPLINK_STEPS.ADD_CARD
    },
    isADP() {
      return isADPConnector(this.connector)
    },
    isManualFallback() {
      return this.deeplinkStep === DEEPLINK_STEPS.MANUAL_FALLBACK
    },
    isReturnUser() {
      return this.taskList.length > 0 && !this.taskWorkflowTypeIsTax
    },
    isPreselectedCompanySingle() {
      return (
        this.incomeSourcesOfTypeChecking?.length === 1 &&
        this.showDetectedIncomeSourcesFlow
      )
    },
    isPreselectedCompanyMultiple() {
      return (
        this.incomeSourcesOfTypeChecking?.length > 1 &&
        this.showDetectedIncomeSourcesFlow
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.skeleton-template-wrap {
  background: var(--white);
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: var(--containerBorderRadius);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  transform: translate3d(0, 0, 0);
  opacity: 1;
  z-index: 2;
  will-change: z-index, opacity;
  &.dark {
    background: var(--darkModeBg);
  }
}
</style>
