export const ATOMIC_BRANDING = {
  NAME: 'Atomic',
  BRAND_COLOR: '#4B23BB',
  BORDER_RADIUS: '8px',
  LINK_COLOR: '#4d5373',
  OVERLAY_COLOR: 'rgba(33, 37, 61, 0.24)',
  BUTTON_TEXT_COLOR: '#FFFFFF',
  BUTTON_STYLE_SOLID: 'solid',
  BUTTON_STYLE_HOLLOW: 'hollow',
  ICON_BACKGROUND_PURPLE: 'rgb(33, 37, 61)',
  ICON_BACKGROUND_PINK: 'rgb(230, 55, 87)',
  ICON_BACKGROUND_YELLOW: 'rgb(246, 195, 68)',
  ICON_BACKGROUND_BLUE: 'rgb(51, 102, 255)',
  ICON_BACKGROUND_GREEN: 'rgb(63, 164, 126)',
  DEFAULT_GRAY: '#f4f6fa',
  DARKMODE_BLACK_OVERRIDE: 'rgba(255,255,255,.1)',
  DEFAULT_FONT: 'TT Norms'
}
