<template>
  <div :class="pageClasses">
    <div
      class="app-content"
      v-focus-trap:permanent="{
        waitBeforeFocus: 2000,
        shouldTrapFocus: !useInlineLayout
      }"
    >
      <slot></slot>
      <PoweredByAtomic v-if="showPoweredByAtomic" />
    </div>
    <ModalWrap />
    <PopoverWrap />
    <SkeletonWrap tabindex="-1" />
  </div>
</template>

<script>
import ModalWrap from '@/components/Modal/ModalWrap.vue'
import PopoverWrap from '@/components/Popover/PopoverWrap.vue'
import SkeletonWrap from '@/components/SkeletonLoader/SkeletonWrap.vue'
import PoweredByAtomic from '@/components/Shared/PoweredByAtomic.vue'
import { mapGetters, mapState } from 'vuex'
import { customCSSIsEnabledAndUsed } from '@/util/theme'

export default {
  name: 'PageWrap',
  components: {
    ModalWrap,
    PopoverWrap,
    SkeletonWrap,
    PoweredByAtomic
  },
  computed: {
    pageClasses() {
      return {
        dark: this.useDarkMode,
        'inline-page-wrap': this.useInlineLayout,
        'page-wrap': !this.useInlineLayout
      }
    },
    showPoweredByAtomic() {
      return (
        this.poweredByAtomic ||
        customCSSIsEnabledAndUsed({ userData: this.userData })
      )
    },
    ...mapGetters('theme', [
      'useDarkMode',
      'poweredByAtomic',
      'useInlineLayout'
    ]),
    ...mapState('user', ['userData'])
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  background-color: var(--white);
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  height: 690px;
  left: 50%;
  margin: -356px 0 0 -180px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  width: 376px;
  container-type: inline-size;
  border-radius: var(--containerBorderRadius);

  &.dark {
    background-color: var(--darkModeBg);
  }

  @media (max-width: 850px) {
    border-radius: 0;
    height: 100%;
    left: 0;
    margin: 0;
    top: 0;
    width: 100%;
    box-shadow: none;
  }
}

.inline-page-wrap {
  container-type: inline-size;
  background-color: var(--white);
  overflow: hidden;
  border-radius: 0;
  height: 100%;
  left: 0;
  margin: 0;
  top: 0;
  width: 100%;
  box-shadow: none;

  .dark & {
    background-color: var(--darkModeBg);
  }
}
.page-wrap,
.inline-page-wrap {
  .app-content {
    transform-origin: center center;
    border-radius: var(--containerBorderRadius);
    height: 100%;
    position: relative;

    @media (max-width: 850px) {
      border-radius: 0;
    }
  }
}
</style>
