import * as Sentry from '@sentry/vue'
let sentryInstance

export default {
  install: (app, router) => {
    Sentry.init({
      app,
      dsn: 'https://56e4d9f958fb4911add637ce5633199e@o516219.ingest.sentry.io/5622380',
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.httpClientIntegration({
          failedRequestStatusCodes: [[400, 599]],
          failedRequestTargets: [
            /^(?!.*rest\.ably\.io).*$/ // Ignore ably errors. If a user's browser tab is left open, Ably will start 401ing over time, and we don't care about that.
          ]
        }),
        Sentry.extraErrorDataIntegration({
          depth: 5
        }),
        Sentry.captureConsoleIntegration({
          levels: ['error']
        })
        // TODO: Sentry.replayIntegration()
      ],
      tracePropagationTargets: ['localhost', 'api.atomicfi.com', /^\//],
      tracesSampleRate: 0.25,
      environment: import.meta.env.MODE
    })

    sentryInstance = {
      log: (message) => Sentry.captureMessage(message)
    }

    app.config.globalProperties.$sentry = sentryInstance
  },
  get() {
    return sentryInstance
  }
}
