import { version, magic } from '@/util/native/methods/version'

export async function features(info) {
  const { number, os } = info ?? (await version())

  return {
    EVALUATE: true,
    FEATURES: true,
    REQUEST:
      (os === 'android' && number >= magic(3, 0, 0)) ||
      (os === 'ios' && number >= magic(3, 0, 0)),
    SHOW:
      (os === 'android' && number >= magic(3, 0, 0)) ||
      (os === 'ios' && number >= magic(3, 0, 0)),
    HIDE:
      (os === 'android' && number >= magic(3, 0, 0)) ||
      (os === 'ios' && number >= magic(3, 0, 0)),
    NAVIGATE:
      (os === 'android' && number >= magic(3, 0, 0)) ||
      (os === 'ios' && number >= magic(3, 0, 0)),
    VERSION: true,
    WAIT: true,
    MUPPET:
      (os === 'android' && number >= magic(3, 4, 0)) ||
      (os === 'ios' && number >= magic(3, 4, 0))
  }
}
