<template>
  <button class="content-only helper-text" @click="method">
    <span class="icon-bg"><IconInformation :size="16" stroke="#376de1" /></span>
    {{ text }}
  </button>
</template>

<script>
import IconInformation from '@/components/Icons/Atomic/IconInformation.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'HelpAction',
  props: {
    text: String,
    method: Function
  },
  components: {
    IconInformation
  },
  computed: {
    ...mapGetters('company', ['brandColor'])
  }
}
</script>

<style lang="scss" scoped>
.icon-bg {
  background: #e8f0ff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--marginBase);
}
.helper-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #376de1;
  text-decoration: none;
  font-size: 1.4rem;
  margin-bottom: calc(var(--marginBase) * 2);
}
</style>
