<template>
  <div class="overlay-wrap" :class="{ dark: useDarkMode }">
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppOverlay',
  computed: {
    ...mapGetters('theme', ['overlayColor', 'useDarkMode'])
  }
}
</script>

<style lang="scss" scoped>
.overlay-wrap {
  background-color: var(--overlayColor);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  @media (max-width: 850px) {
    background-color: var(--white);
  }
  &.dark {
    @media (max-width: 850px) {
      background-color: var(--darkModeBg);
    }
  }
}
</style>
