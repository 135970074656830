import { screenReaderAccessibleNumber } from '@/util/general'
import { getPaymentMethodAccountType, isBank } from '@/util/pay-link'

export const shared = {
  actionNeeded: 'Action needed',
  activateEditMode: 'Activate edit mode',
  backToSelections: 'Back to selections',
  collapse: 'Collapse',
  collapseFullSelectionList: 'Collapse full selection list',
  currentPaymentMethod: 'Current payment method',
  deactivateEditMode: 'Deactivate edit mode',
  edit: 'Edit',
  editingSelections: 'Editing selections',
  expand: 'Expand',
  finishVerifyingAccount: 'Finish verifying your account',
  finishVerifyingAccountSubtitle: ({ companyName }) =>
    `${companyName} needs to verify your account to switch your payment.`,
  fullSelectionList: 'Full selection list',
  inProgressPaymentMethod: 'In progress payment method',
  linked: 'Linked',
  linkedPaymentMethod: 'Linked payment method',
  linkingCompleted: 'Linking completed',
  linkingFailed: 'Linking failed',
  linkingInProgress: 'Linking in progress',
  managePayment: 'Manage payment',
  paymentMethod: 'Payment method',
  paymentMethodAriaDescription: ({ paymentMethod }) =>
    isBank(paymentMethod)
      ? `${paymentMethod.title} ${getPaymentMethodAccountType(
          paymentMethod
        )} account ending in ${screenReaderAccessibleNumber(
          paymentMethod.accountNumber
        )}`
      : `${paymentMethod.title} ${
          paymentMethod.brand
        } card ending in ${screenReaderAccessibleNumber(
          paymentMethod.lastFour
        )}`,
  pending: 'Pending',
  remove: 'Remove',
  removeSelection: 'Remove selection',
  selected: 'Selected',
  selectedPayments: 'Selected payments',
  selectionList: 'Selection list',
  selections: 'selections',
  selectPaymentMethod: 'Select payment method',
  skip: 'Skip',
  status: 'Status',
  tryAgain: 'Try again',
  tryDifferentAccount: 'Try different account',
  unsupported: 'Unsupported',
  updatePaymentMethod: 'Update payment method',
  verifyAccount: 'Verify account',
  viewFullSelectionList: 'View full selection list'
}
