import { createMachine, actions } from 'xstate'

import { authenticationGuards } from './guards'
import { authenticationActions } from './actions'
import { AUTHENTICATION_EVENTS } from './events'
import { AUTHENTICATION_STATES } from './states'

const { choose } = actions

export const authenticationMachine = createMachine(
  {
    id: 'authentication',
    context: {
      currentStepIdx: 0,
      // these three should really be covered by a parent machine, but
      // we manage them here to reduce the scope of the first phase of
      // this migration
      showConfirmDistributionPage: null,
      productsIncludeDeposit: null,
      isConfigurableConnector: null,
      usesMultiplePayrollProviders: null,
      updatedDepositExperience: null,
      initialState: null,
      query: null,
      featureFlags: null
    },
    initial: AUTHENTICATION_STATES.IDLE,
    on: {
      [AUTHENTICATION_EVENTS.RESET_STEP]: {
        actions: 'resetStep'
      },
      [AUTHENTICATION_EVENTS.RESET_MACHINE]: {
        target: AUTHENTICATION_STATES.IDLE
      }
    },
    states: {
      [AUTHENTICATION_STATES.IDLE]: {
        on: {
          [AUTHENTICATION_EVENTS.INITIALIZE]: {
            target: AUTHENTICATION_STATES.INITIALIZING,
            actions: 'initialize'
          }
        }
      },
      [AUTHENTICATION_STATES.INITIALIZING]: {
        always: [
          {
            target:
              AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD,
            cond: 'useConfigurableConnectorSearch'
          },
          {
            target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
            cond: 'companyHasMultiplePayrollProviders'
          },
          {
            target: AUTHENTICATION_STATES.SELECTING_CO_AUTH_CONNECTOR,
            cond: 'useCoAuthConnector'
          },
          {
            target: AUTHENTICATION_STATES.UPLINK_INTERSTITIAL,
            cond: 'showUplinkInterstitial'
          },
          {
            target: AUTHENTICATION_STATES.UPLINK_AUTHENTICATION,
            cond: 'isTrueAuthSessionContext'
          },
          {
            target: AUTHENTICATION_STATES.UPLINK_AUTHENTICATION,
            cond: 'isScopePayLinkAndNotSmartAuth'
          },
          {
            target: AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD,
            cond: 'showADPInterstitial'
          },
          {
            target: AUTHENTICATION_STATES.SOCIAL_SECURITY_INTERSTITIAL,
            cond: 'showSocialSecurityInterstitial'
          },
          {
            target: AUTHENTICATION_STATES.SOCIAL_SECURITY_LOGIN_OPTIONS,
            cond: 'showSocialSecurityLoginOptions'
          },
          {
            target: AUTHENTICATION_STATES.SOCIAL_SECURITY_SSI,
            cond: 'showSocialSecuritySsi'
          },
          {
            target: AUTHENTICATION_STATES.RECOVERING_LOGIN,
            cond: 'showADPLoginRecovery',
            actions: ['launchADPRecoveryFlow']
          },
          {
            target: AUTHENTICATION_STATES.BANK_INTERSTITIAL,
            cond: 'showBankInterstitial'
          },
          {
            target: AUTHENTICATION_STATES.LOGGING_IN,
            cond: 'showADPLogin'
          },
          {
            target: AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST,
            cond: 'companyUsesSmartAuth'
          },
          { target: AUTHENTICATION_STATES.LOGGING_IN }
        ],
        exit: 'assignInitialState'
      },
      [AUTHENTICATION_STATES.UPLINK_INTERSTITIAL]: {
        on: {
          [AUTHENTICATION_EVENTS.LAUNCH_UPLINK]: {
            target: AUTHENTICATION_STATES.UPLINK_AUTHENTICATION
          },
          [AUTHENTICATION_EVENTS.AUTHENTICATION_FALLBACK]: {
            target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD
          },
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
              cond: 'companyHasMultiplePayrollProviders',
              actions: ['resetUplinkConfigurationToDefaultConnector']
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: choose([
                {
                  cond: 'canGoBackToSearchFromDeeplink',
                  actions: ['goToSearchPage']
                },
                {
                  actions: [
                    'goBackInSelectedCompanyHistory',
                    'goToPreviousPage'
                  ]
                }
              ])
            }
          ]
        }
      },
      [AUTHENTICATION_STATES.BANK_INTERSTITIAL]: {
        on: {
          [AUTHENTICATION_EVENTS.GO_TO_LOGIN]: {
            target: AUTHENTICATION_STATES.LOGGING_IN
          },
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
            }
          ]
        }
      },
      [AUTHENTICATION_STATES.SOCIAL_SECURITY_INTERSTITIAL]: {
        on: {
          [AUTHENTICATION_EVENTS.INITIALIZE]: {
            target: AUTHENTICATION_STATES.INITIALIZING,
            actions: 'initialize'
          },
          [AUTHENTICATION_EVENTS.LAUNCH_UPLINK]: {
            target: AUTHENTICATION_STATES.UPLINK_AUTHENTICATION
          },
          [AUTHENTICATION_EVENTS.SELECTED_RECEIVE_SSA_PAYMENTS]: {
            target: AUTHENTICATION_STATES.SOCIAL_SECURITY_LOGIN_OPTIONS
          },
          [AUTHENTICATION_EVENTS.SELECTED_RECEIVE_SSA_SSI]: {
            target: AUTHENTICATION_STATES.SOCIAL_SECURITY_SSI
          },
          [AUTHENTICATION_EVENTS.SELECTED_SSA_EMPLOYEE]: {
            target: AUTHENTICATION_STATES.LOGGING_IN,
            actions: ['changeSelectedCompany']
          },
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
            }
          ]
        }
      },
      [AUTHENTICATION_STATES.SOCIAL_SECURITY_LOGIN_OPTIONS]: {
        on: {
          [AUTHENTICATION_EVENTS.SELECTED_SSA_LOGIN]: {
            target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD,
            actions: ['initialize']
          },
          [AUTHENTICATION_EVENTS.SWITCH_METHOD]: {
            target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD,
            actions: ['initialize']
          },
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              target: AUTHENTICATION_STATES.SOCIAL_SECURITY_INTERSTITIAL,
              cond: 'showSocialSecurityInterstitial'
            }
          ]
        }
      },
      [AUTHENTICATION_STATES.SOCIAL_SECURITY_SSI]: {
        on: {
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              target: AUTHENTICATION_STATES.SOCIAL_SECURITY_INTERSTITIAL,
              cond: 'showSocialSecurityInterstitial'
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
            }
          ]
        }
      },
      [AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD]: {
        on: {
          [AUTHENTICATION_EVENTS.SELECTED_FORGOT_LOGIN]: {
            target: AUTHENTICATION_STATES.RECOVERING_LOGIN,
            actions: ['launchADPRecoveryFlow']
          },
          [AUTHENTICATION_EVENTS.SELECTED_CREDENTIALS_KNOWN]: {
            target: AUTHENTICATION_STATES.LOGGING_IN
          },
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
              cond: 'companyHasMultiplePayrollProviders',
              actions: ['resetUplinkConfigurationToDefaultConnector']
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
            }
          ]
        }
      },
      [AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER]: {
        on: {
          [AUTHENTICATION_EVENTS.SELECTED_PAYROLL_PROVIDER]: [
            {
              target: AUTHENTICATION_STATES.SELECTING_CO_AUTH_CONNECTOR,
              cond: 'useCoAuthConnector'
            },
            {
              target: AUTHENTICATION_STATES.UPLINK_INTERSTITIAL,
              cond: 'showUplinkInterstitial'
            },
            {
              target: AUTHENTICATION_STATES.RECOVERING_LOGIN,
              actions: ['launchADPRecoveryFlow'],
              cond: 'showADPLoginRecovery'
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD,
              cond: 'showADPInterstitial'
            },
            {
              target: AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST,
              cond: 'defaultConnectorUsesSmartAuth'
            },
            {
              target: AUTHENTICATION_STATES.LOGGING_IN
            }
          ],
          [AUTHENTICATION_EVENTS.BACK]: {
            target: AUTHENTICATION_STATES.IDLE,
            actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
          }
        }
      },
      [AUTHENTICATION_STATES.LOGGING_IN]: {
        on: {
          [AUTHENTICATION_EVENTS.COMPLETED_LOGIN]: {
            actions: choose([
              {
                cond: 'shouldStartTask',
                actions: ['startTask']
              },
              {
                actions: ['launchConfirmDistribution']
              }
            ])
          },
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              actions: ['dispatchGoBackStep'],
              cond: 'hasPreviousStep'
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              cond: 'usingUserAutomatedDevice',
              actions: ['goToPreviousPage']
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
              cond: 'companyHasMultiplePayrollProviders',
              actions: [
                'resetCoAuthConnectors',
                'resetUplinkConfigurationToDefaultConnector'
              ]
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD,
              cond: 'showADPInterstitial'
            },
            {
              target: AUTHENTICATION_STATES.BANK_INTERSTITIAL,
              cond: 'showBankInterstitial'
            },
            {
              target:
                AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD,
              cond: 'useConfigurableConnectorSearch',
              actions: ['goBackInSelectedCompanyHistory']
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: choose([
                {
                  cond: 'canGoBackToSearchFromDeeplink',
                  actions: ['goToSearchPage']
                },
                {
                  actions: [
                    'goBackInSelectedCompanyHistory',
                    'goToPreviousPage'
                  ]
                }
              ])
            }
          ],
          [AUTHENTICATION_EVENTS.STEP]: { actions: 'setStep' },
          [AUTHENTICATION_EVENTS.GET_HELP]: AUTHENTICATION_STATES.GETTING_HELP,
          [AUTHENTICATION_EVENTS.SWITCH_METHOD]: {
            cond: 'activeConnectorIsAdp',
            target: AUTHENTICATION_STATES.RECOVERING_LOGIN
          },
          [AUTHENTICATION_EVENTS.SELECTED_FORGOT_LOGIN]: [
            {
              target: AUTHENTICATION_STATES.RECOVERING_LOGIN,
              cond: 'showADPInterstitial',
              actions: ['launchADPRecoveryFlow']
            },
            {
              target: AUTHENTICATION_STATES.RECOVERING_LOGIN,
              actions: ['launchRecoveryFlow']
            }
          ],
          [AUTHENTICATION_EVENTS.AUTHENTICATION_FALLBACK]: {
            target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD
          }
        }
      },
      [AUTHENTICATION_STATES.UPLINK_AUTHENTICATION]: {
        on: {
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              actions: ['dispatchGoBackStep'],
              cond: 'hasPreviousStep'
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              cond: 'usingUserAutomatedDevice',
              actions: ['goToPreviousPage']
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
              cond: 'companyHasMultiplePayrollProviders',
              actions: ['resetUplinkConfigurationToDefaultConnector']
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD,
              cond: 'showADPInterstitial'
            },
            {
              target: AUTHENTICATION_STATES.BANK_INTERSTITIAL,
              cond: 'showBankInterstitial'
            },
            {
              target:
                AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD,
              cond: 'useConfigurableConnectorSearch',
              actions: ['goBackInSelectedCompanyHistory']
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
            }
          ],
          [AUTHENTICATION_EVENTS.AUTHENTICATION_FALLBACK]: {
            target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD
          }
        }
      },
      [AUTHENTICATION_STATES.RECOVERING_LOGIN]: {
        on: {
          [AUTHENTICATION_EVENTS.COMPLETED_LOGIN]: {
            actions: choose([
              {
                cond: 'shouldStartTask',
                actions: ['startTask']
              },
              {
                actions: ['launchConfirmDistribution']
              }
            ])
          },
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              actions: ['dispatchGoBackStep'],
              cond: 'hasPreviousStep'
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
              cond: 'companyHasMultiplePayrollProviders',
              actions: ['resetUplinkConfigurationToDefaultConnector']
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD,
              cond: 'showADPInterstitial'
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              cond: 'showADPLoginRecovery',
              actions: choose([
                {
                  cond: 'canGoBackToSearchFromDeeplink',
                  actions: ['goToSearchPage']
                },
                {
                  actions: ['goToPreviousPage']
                }
              ])
            },
            {
              target: AUTHENTICATION_STATES.GETTING_HELP
            }
          ],
          [AUTHENTICATION_EVENTS.STEP]: { actions: 'setStep' },
          [AUTHENTICATION_EVENTS.SWITCH_METHOD]: {
            target: AUTHENTICATION_STATES.LOGGING_IN
          }
        }
      },
      [AUTHENTICATION_STATES.GETTING_HELP]: {
        on: {
          [AUTHENTICATION_EVENTS.BACK]: AUTHENTICATION_STATES.LOGGING_IN,
          [AUTHENTICATION_EVENTS.SELECTED_FORGOT_LOGIN]: {
            target: AUTHENTICATION_STATES.RECOVERING_LOGIN,
            actions: ['launchRecoveryFlow']
          }
        }
      },
      [AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD]: {
        on: {
          [AUTHENTICATION_EVENTS.SELECTED_CONNECTOR]: {
            target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD,
            actions: ['initialize']
          },
          [AUTHENTICATION_EVENTS.SEARCH_NOT_FOUND]: [
            {
              target: AUTHENTICATION_STATES.PROVIDING_CONFIGURABLE_CONNECTOR_ID,
              cond: 'hasUserProvidedFlag',
              actions: ['assignQuery']
            },
            {
              target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD,
              cond: 'supportsUserProvidedConnectorParameters'
            },
            {
              target: AUTHENTICATION_STATES.CONFIGURABLE_CONNECTOR_NOT_FOUND,
              actions: ['assignQuery']
            }
          ],
          [AUTHENTICATION_EVENTS.BACK]: {
            target: AUTHENTICATION_STATES.IDLE,
            actions: choose([
              {
                cond: 'canGoBackToSearchFromDeeplink',
                actions: ['goToSearchPage']
              },
              {
                actions: [
                  'clearConnectorSearch',
                  'goBackInSelectedCompanyHistory',
                  'goToPreviousPage'
                ]
              }
            ])
          }
        }
      },
      [AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD]: {
        always: [
          {
            target: AUTHENTICATION_STATES.SELECTING_CO_AUTH_CONNECTOR,
            cond: 'useCoAuthConnector'
          },
          {
            target: AUTHENTICATION_STATES.UPLINK_INTERSTITIAL,
            cond: 'showUplinkInterstitial'
          },
          {
            target: AUTHENTICATION_STATES.UPLINK_AUTHENTICATION,
            cond: 'isScopePayLinkAndNotSmartAuth'
          },
          {
            target: AUTHENTICATION_STATES.SELECTING_LOGIN_METHOD,
            cond: 'showADPInterstitial'
          },
          {
            target: AUTHENTICATION_STATES.RECOVERING_LOGIN,
            cond: 'showADPLoginRecovery',
            actions: ['launchADPRecoveryFlow']
          },
          {
            target: AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST,
            cond: 'companyUsesSmartAuth'
          },
          {
            target: AUTHENTICATION_STATES.LOGGING_IN
          }
        ]
      },
      [AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR]: {
        on: {
          [AUTHENTICATION_EVENTS.SEARCHED_CONNECTOR]: {
            target: AUTHENTICATION_STATES.REVIEWING_CONFIGURABLE_CONNECTOR,
            actions: ['assignQuery']
          },
          [AUTHENTICATION_EVENTS.SEARCH_NOT_FOUND]: [
            {
              target: AUTHENTICATION_STATES.PROVIDING_CONFIGURABLE_CONNECTOR_ID,
              cond: 'hasUserProvidedFlag'
            },
            {
              target: AUTHENTICATION_STATES.CONFIGURABLE_CONNECTOR_NOT_FOUND
            }
          ],
          [AUTHENTICATION_EVENTS.BACK]: {
            target: AUTHENTICATION_STATES.IDLE,
            actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
          }
        }
      },
      [AUTHENTICATION_STATES.REVIEWING_CONFIGURABLE_CONNECTOR]: {
        on: {
          [AUTHENTICATION_EVENTS.SELECTED_CONNECTOR]: {
            target: AUTHENTICATION_STATES.LOGGING_IN
          },
          [AUTHENTICATION_EVENTS.SEARCH_NOT_FOUND]: [
            {
              target: AUTHENTICATION_STATES.PROVIDING_CONFIGURABLE_CONNECTOR_ID,
              cond: 'hasUserProvidedFlag'
            },
            {
              target: AUTHENTICATION_STATES.CONFIGURABLE_CONNECTOR_NOT_FOUND
            }
          ],
          [AUTHENTICATION_EVENTS.BACK]: {
            target:
              AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD,
            cond: 'useConfigurableConnectorSearch'
          }
        }
      },
      [AUTHENTICATION_STATES.CONFIGURABLE_CONNECTOR_NOT_FOUND]: {
        on: {
          [AUTHENTICATION_EVENTS.BACK]: {
            target:
              AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD,
            cond: 'useConfigurableConnectorSearch'
          }
        }
      },
      [AUTHENTICATION_STATES.PROVIDING_CONFIGURABLE_CONNECTOR_ID]: {
        on: {
          [AUTHENTICATION_EVENTS.SELECTED_CONNECTOR]: {
            target: AUTHENTICATION_STATES.DETERMINING_AUTHENTICATION_METHOD,
            actions: ['initialize']
          },
          [AUTHENTICATION_EVENTS.BACK]: {
            target:
              AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD,
            cond: 'useConfigurableConnectorSearch'
          }
        }
      },
      [AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST]: {
        on: {
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              actions: ['dispatchGoBackStep'],
              cond: 'hasPreviousStep'
            },
            {
              target:
                AUTHENTICATION_STATES.SEARCHING_CONFIGURABLE_CONNECTOR_TYPEAHEAD,
              cond: 'useConfigurableConnectorSearch'
            },
            {
              target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
              cond: 'companyHasMultiplePayrollProviders',
              actions: ['resetUplinkConfigurationToDefaultConnector']
            },
            {
              target: AUTHENTICATION_STATES.SOCIAL_SECURITY_LOGIN_OPTIONS,
              cond: 'showSocialSecurityLoginOptions'
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: choose([
                {
                  cond: 'canGoBackToSearchFromDeeplink',
                  actions: ['goToSearchPage']
                },
                {
                  actions: ['goToPreviousPage']
                }
              ])
            }
          ],
          [AUTHENTICATION_EVENTS.STEP]: { actions: 'setStep' },
          [AUTHENTICATION_EVENTS.SMART_AUTH_GET_HELP]:
            AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST_GETTING_HELP,
          [AUTHENTICATION_EVENTS.SMART_AUTH_FINISHED]:
            AUTHENTICATION_STATES.SMART_AUTH_FINISHED
        }
      },
      [AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST_GETTING_HELP]: {
        on: {
          [AUTHENTICATION_EVENTS.BACK]:
            AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST,
          [AUTHENTICATION_EVENTS.SWITCH_METHOD]:
            AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST
        }
      },
      [AUTHENTICATION_STATES.SMART_AUTH_FINISHED]: {
        on: {
          [AUTHENTICATION_EVENTS.BACK]: {
            target: AUTHENTICATION_STATES.IDLE,
            actions: ['goToPreviousPage']
          },
          [AUTHENTICATION_EVENTS.SWITCH_METHOD]:
            AUTHENTICATION_STATES.SMART_AUTH_DATA_REQUEST
        }
      },
      [AUTHENTICATION_STATES.SELECTING_CO_AUTH_CONNECTOR]: {
        always: [
          {
            target: AUTHENTICATION_STATES.ADP_COAUTH_INTERSTITIAL,
            cond: 'activeConnectorIsAdp'
          },
          {
            target: AUTHENTICATION_STATES.LOGGING_IN,
            cond: 'useCoAuthConnector',
            actions: [
              'setCoauthConnectorSettings',
              'enforceCoAuthConnectorBrandingAndAuthentication'
            ]
          }
        ]
      },
      [AUTHENTICATION_STATES.ADP_COAUTH_INTERSTITIAL]: {
        on: {
          [AUTHENTICATION_EVENTS.CONTINUE]: [
            {
              target: AUTHENTICATION_STATES.LOGGING_IN,
              actions: [
                'setCoauthConnectorSettings',
                'enforceCoAuthConnectorBrandingAndAuthentication'
              ]
            }
          ],
          [AUTHENTICATION_EVENTS.BACK]: [
            {
              target: AUTHENTICATION_STATES.SELECTING_PAYROLL_PROVIDER,
              cond: 'companyHasMultiplePayrollProviders',
              actions: ['resetUplinkConfigurationToDefaultConnector']
            },
            {
              target: AUTHENTICATION_STATES.IDLE,
              actions: ['goBackInSelectedCompanyHistory', 'goToPreviousPage']
            }
          ]
        },
        entry: ['hideTaskWorkflowBrandingHeader'],
        exit: ['showTaskWorkflowBrandingHeader']
      }
      // A complete step would be useful if this were truly an actor
      // but since we retain the same instance whenever we navigate back,
      // it's better to just leave it in the old state
      // [AUTHENTICATION_STATES.COMPLETE]: {
      // on: {}
      // }
    }
  },
  {
    actions: authenticationActions,
    guards: authenticationGuards
  }
)
