import { USER_MESSAGE_TYPES } from '@/util/constants'
import Ably from '@/plugins/ably'

export function subscribeRealtimeUserUpdates({
  store,
  userId,
  onUserAccountsUpdated = () => {}
}) {
  const userChannel = Ably.get().channels.get(`${userId}:update`)

  if (store?.getters?.['main/isScopePayLink']) {
    const handler = (message) => {
      const { cards } = message.data

      // This will always be a single card since it's coming from the update user endpoint
      if (cards) store.dispatch('user/updateCard', cards[0])
      onUserAccountsUpdated({ store, cards })

      // Once we receive card data unsubscribe from future updates in case the user does multiple switches
      // we'll want to subscribe for each individual switch, and not have duplicate handlers
      userChannel.unsubscribe(USER_MESSAGE_TYPES.USER_CARDS_UPDATED, handler)
    }

    userChannel.subscribe(USER_MESSAGE_TYPES.USER_CARDS_UPDATED, handler)
  } else {
    userChannel.subscribe(
      USER_MESSAGE_TYPES.USER_ACCOUNTS_UPDATED,
      (message) => {
        const { accounts } = message.data

        if (accounts) store.dispatch('user/filterAccounts', accounts)
        onUserAccountsUpdated({ store, accounts })
      }
    )
  }
}

export function publishUserSessionEvent({ store, eventName, eventData }) {
  Ably.get()
    .channels.get(`${store.state.user.userData._id}:sessions`)
    .publish(eventName, eventData)
}

export function subscribeUserSessionEvent({ store, eventName }, callback) {
  const channel = Ably.get().channels.get(
    `${store.state.user.userData._id}:sessions`
  )
  channel.subscribe(eventName, callback)
}

export function unsubscribeUserSessionEvent({ store }, callback) {
  const channel = Ably.get().channels.get(
    `${store.state.user.userData._id}:sessions`
  )
  channel.unsubscribe(callback)
}

export function subscribeTrueAuthAppAnalytics({ store }, callback) {
  subscribeUserSessionEvent(
    { store, eventName: 'true-auth-app-interaction' },
    callback
  )

  store.dispatch('analytics/updateTrackAnalyticsEvents', false)
  store.dispatch('analytics/updateTrueAuthInteractionEventCallback', callback)
}

export function unsubscribeTrueAuthAppAnalytics({ store }) {
  if (!store.state.analytics.trueAuthInteractionEventCallback) {
    return
  }

  unsubscribeUserSessionEvent(
    { store },
    store.state.analytics.trueAuthInteractionEventCallback
  )

  store.dispatch('analytics/resetAnalyticsTracking')
}
