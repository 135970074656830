export const exitModal = {
  generic: {
    tax: {
      title: '¿Salir de vincular documentos tributarios?',
      description:
        'Su progreso se perderá y cualquier dato ingresado se eliminará por su seguridad.'
    },
    deposit: {
      title: '¿Salir de la configuración de depósito directo?',
      description:
        'Su progreso se perderá y cualquier dato ingresado se eliminará por su seguridad.'
    },
    verify: {
      title: '¿No estás listo para vincular su cuenta?',
      description:
        'Su progreso se perderá y cualquier dato ingresado se eliminará por su seguridad.'
    },
    switch: {
      title: '¿Terminaste de vincular los pagos?',
      description: 'Vuelve en cualquier momento para vincular.'
    }
  },
  search: {
    title: `¿No encuentra a su empleador o proveedor de nómina?`,
    helperAction: 'Cómo encontrar su empleador/nómina'
  },
  login: {
    title: '¿Tiene problemas para iniciar sesión?',
    description:
      'Aprenda dónde encontrar la información de su cuenta o configure el depósito directo manualmente.',
    helperAction: 'Cómo encontrar sus credenciales'
  },
  distribution: {
    title: '¿No estás listo para hacer la actualización?',
    description: {
      canChange:
        'La mayoría de las personas eligen su sueldo completo, pero usted puede establecer una cantidad menor.',
      noChange:
        'Vuelva en cualquier momento para terminar de actualizar su configuración de depósito directo.'
    }
  },
  actionItems: {
    changeDepositAmount: 'Cambiar monto del depósito',
    setUpManually: 'Configurar manualmente'
  }
}
