import { preloadImages } from '@/util/preloader'
import {
  ROUTES,
  PRODUCTS,
  DEEPLINK_STEPS,
  TASK_WORKFLOW_TYPES,
  TASK_WORKFLOW_VIEW
} from '@/util/constants'
import { navigateToAuthenticationPage } from '@/util/authentication'
import { prepareKeyboardFocus } from '@/util/magic-focus'
import Analytics from '@/plugins/analytics'
import { checkIfProductExistsInTaskWorkflowProducts } from '@/util/task-workflow'
import { triggerAnalyticEvent } from '@/util/analytics'

export async function navigateToCompanyLogin({ companyId, store, router }) {
  await navigateToLogin({ companyId, store, router })
}

export async function navigateToPayrollLogin({ connectorId, store, router }) {
  await navigateToLogin({ connectorId, store, router })
}

export async function prepareLogin({ companyId, connectorId, store }) {
  store.dispatch('progress/clearProgressData')

  const fetchEntityAction = companyId
    ? 'company/fetchCompanyDetails'
    : 'company/fetchConnectorDetails'

  prepareKeyboardFocus()

  await store.dispatch(fetchEntityAction, companyId || connectorId)
  await _preloadConnectorBrandingImages({ store })
}

export async function navigateToLogin({
  companyId,
  connectorId,
  store,
  router
}) {
  await prepareLogin({ companyId, connectorId, store })
  await navigateToAuthenticationPage({ store, router })
}

export function navigateToPayrollSearch({ router }) {
  router.push({ name: ROUTES.SEARCH_PAYROLL })
}

export async function navigateToFranchiseSearch({ companyId, store, router }) {
  await store.dispatch('company/fetchFranchiseParentDetails', companyId)
  router.push({ name: ROUTES.SEARCH_FRANCHISE })
}

export function determineNextPageFromWelcome({ store, router }) {
  if (
    store.getters['experiment/alwaysShowWelcomeScreen'] &&
    store.getters['user/userHasMultipleAccounts'] &&
    store.state.main?.deeplink?.step
  ) {
    router.push({ name: ROUTES.ACCOUNT })
  } else if (
    store.getters['experiment/alwaysShowWelcomeScreen'] &&
    store.state.main?.deeplink?.step
  ) {
    navigateToDeeplink({ store, router })
  } else {
    navigateToAccountOrSearch({ store, router })
  }
}

// Using store and router from component for better testing
export function determineNextPageFromAccounts({ store, router }) {
  return store.state.taskHistory?.selectedConnectionToModify
    ? navigateToCompanyLogin({
        companyId: store.state.taskHistory?.selectedConnectionToModify,
        store,
        router
      })
    : store.state.main?.deeplink?.step
    ? navigateToDeeplink({ store, router })
    : router.push({ name: ROUTES.SEARCH_COMPANY })
}

export function navigateToAccountOrSearch({ store, router }) {
  let nextRoute = ROUTES.SEARCH_COMPANY

  if (checkIfProductExistsInTaskWorkflowProducts(PRODUCTS.DEPOSIT)) {
    nextRoute = store.getters['user/userHasMultipleAccounts']
      ? ROUTES.ACCOUNT
      : ROUTES.SEARCH_COMPANY
  }

  router.push({ name: nextRoute })
}

export function payrollSearchAnalytics({
  interaction,
  searchQuery,
  resetSelectedCompanyHistory = true,
  store
} = {}) {
  const analyticsInstance = Analytics.get()

  if (searchQuery) {
    store.dispatch('search/updateSearchedCompanyName', searchQuery)
  }

  if (resetSelectedCompanyHistory) {
    store.dispatch('company/resetSelectedCompanyHistory')
  }

  analyticsInstance.track({
    event: interaction,
    payload: {
      ...(searchQuery ? { searchQuery } : {})
    }
  })
}

export async function navigateToDeeplink({ store, router }) {
  const entry = store.state.main?.deeplink
  switch (entry?.step) {
    case DEEPLINK_STEPS.SEARCH_COMPANY: {
      await _deeplinkToSearchCompany({ store, router })
      break
    }
    case DEEPLINK_STEPS.SEARCH_PAYROLL: {
      await _deeplinkToSearchPayroll({ store, router, entry })
      break
    }
    case DEEPLINK_STEPS.LOGIN_COMPANY: {
      await _deeplinkToLoginCompany({ store, router, entry })
      break
    }
    case DEEPLINK_STEPS.LOGIN_PAYROLL: {
      await _deeplinkToLoginPayroll({ store, router, entry })
      break
    }
    case DEEPLINK_STEPS.GENERATE_DEPOSIT_PDF: {
      await _deeplinkToGenerateDepositPDF({ store, router })
      break
    }
    case DEEPLINK_STEPS.MANUAL_FALLBACK: {
      await _deeplinkToManualFallback({ store, router })
      break
    }
  }
}

async function _deeplinkToSearchCompany({ store, router }) {
  triggerAnalyticEvent({
    eventName: 'Initialized Deeplink Search Company'
  })
  _determineDeeplinkRoute({ store, router, route: ROUTES.SEARCH_COMPANY })
}

async function _deeplinkToSearchPayroll({ store, router, entry }) {
  triggerAnalyticEvent({
    eventName: 'Initialized Deeplink Search Payroll'
  })
  await store.dispatch('search/updateSearchedCompanyName', entry.companyName)
  _determineDeeplinkRoute({ store, router, route: ROUTES.SEARCH_PAYROLL })
}

async function _deeplinkToLoginCompany({ store, router, entry }) {
  triggerAnalyticEvent({
    eventName: 'Initialized Deeplink Company Login'
  })
  await navigateToCompanyLogin({
    companyId: entry.companyId,
    store,
    router
  })
}

async function _deeplinkToLoginPayroll({ store, router, entry }) {
  triggerAnalyticEvent({
    eventName: 'Initialized Deeplink Payroll Login'
  })
  await store.dispatch('search/updateSearchedCompanyName', entry.companyName)
  await navigateToPayrollLogin({
    connectorId: entry.connectorId,
    store,
    router
  })
}

async function _deeplinkToGenerateDepositPDF({ router }) {
  triggerAnalyticEvent({
    eventName: 'Initialized Generate Deposit PDF'
  })
  router.push({ name: ROUTES.GENERATE_DEPOSIT_PDF })
}

async function _deeplinkToManualFallback({ store, router }) {
  triggerAnalyticEvent({
    eventName: 'Initialized Deeplink Manual Fallback'
  })
  const updatedExperiments = {
    ...store.state.main.experiments,
    manualFallback: true
  }
  await store.dispatch('main/updateExperiments', updatedExperiments)
  await store.dispatch(
    'taskWorkflow/updateTaskWorkflowType',
    TASK_WORKFLOW_TYPES.MANUAL_FALLBACK
  )
  await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.INTERSTITIAL.MANUAL_FALLBACK
  })
  router.push({ name: ROUTES.TASK })
}

async function _preloadConnectorBrandingImages({ store }) {
  const { connector, alternativeConnectors = [] } = store.state.company

  await _preloadConnectorImage(connector)

  alternativeConnectors.forEach(_preloadConnectorImage)
}

async function _preloadConnectorImage(connector) {
  if (connector.branding && connector.branding.logo) {
    await preloadImages([connector.branding.logo.url])
  }
}

function _determineDeeplinkRoute({ store, router, route }) {
  if (
    store.getters['experiment/alwaysShowWelcomeScreen'] &&
    router.currentRoute?._value?.name !== ROUTES.WELCOME &&
    router.currentRoute?._value?.name !== ROUTES.ACCOUNT
  ) {
    router.push({ name: ROUTES.WELCOME })
  } else {
    router.push({ name: route })
  }
}
