<template>
  <BadgeBase :backgroundColor="badgeBackgroundColor">
    <IconExclamation :size="18" :stroke="iconColor" />
  </BadgeBase>
</template>

<script>
import BadgeBase from '@/components/Badge/BadgeBase.vue'
import IconExclamation from '@/components/Icons/Atomic/IconExclamation.vue'
import { getGlobalCSSValue } from '@/util/general'

export default {
  name: 'BadgeError',
  components: {
    BadgeBase,
    IconExclamation
  },
  computed: {
    badgeBackgroundColor() {
      return getGlobalCSSValue('--error')
    },
    iconColor() {
      return getGlobalCSSValue('--white')
    }
  }
}
</script>
