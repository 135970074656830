/* eslint-disable no-empty-pattern */
import { makeRequest } from '@/util/requests'
import { createTaskTimer, createFulfillmentTimer } from '@/util/task'
import { get } from 'lodash-es'
import { fadeOutTaskCountdown } from '@/util/animation'
import { ApiError } from '@/util/custom-errors'

const state = {
  taskId: '',
  taskPrompt: {
    requestType: '',
    sections: []
  },
  recoveryFlow: {
    id: '',
    sections: []
  },
  taskTimer: null,
  waitFulfillmentTimer: null,
  authPayload: {},
  hasRequestedDataFromCustomer: false,
  taskCountdown: {
    timer: 0,
    interval: null
  },
  failReason: '',
  taskIdToConvert: null,
  taskCounter: 0
}

const mutations = {
  setCurrentTaskId: (state, id) => {
    state.taskId = id

    // Used by Cypress tests for debugging
    window.taskId = id
  },
  setTaskIdToConvert: (state, id) => {
    state.taskIdToConvert = id

    // Used by Cypress tests for debugging
    window.taskId = id
  },
  setTaskPrompts: (state, taskPrompts) => {
    state.taskPrompt = taskPrompts
  },
  setTaskTimer: (state, timer) => {
    state.taskTimer = timer
  },
  setFulfillmentWaitTimer: (state, timer) => {
    state.waitFulfillmentTimer = timer
  },
  clearAllTaskTimers: (state) => {
    clearTimeout(state.waitFulfillmentTimer)
    clearTimeout(state.taskTimer)
  },
  clearTaskCountdownTimer: (state) => {
    state.taskCountdown.timer = 0
    state.taskCountdown.interval = null
  },
  setAuthPayload: (state, payload) => {
    state.authPayload = { ...payload }
  },
  setRecoveryFlow: (state, flow) => {
    state.recoveryFlow = flow
  },
  setHasRequestedDataFromCustomer: (state, hasRequestedData) => {
    state.hasRequestedDataFromCustomer = hasRequestedData
  },
  setTaskCountdownInterval: (state, interval) => {
    state.taskCountdown.interval = interval
  },
  setCurrentTaskCountdownTime: (state, timer) => {
    state.taskCountdown.timer = timer
  },
  setTaskFailReason: (state, failReason) => {
    state.failReason = failReason
  },
  incrementTaskCounter: (state) => {
    state.taskCounter++
  }
}

const getters = {
  isInLoginRecovery: (state) => {
    return !!state.recoveryFlow.id
  },
  openReplayTaskMetadataKey: (state) => {
    return `Task-${state.taskCounter}`
  }
}

const actions = {
  updateCurrentTaskId({ commit }, payload) {
    commit('setCurrentTaskId', payload.taskId)
  },
  updateTaskIdToConvert({ commit }, taskIdToConvert) {
    commit('setTaskIdToConvert', taskIdToConvert)
  },
  incrementTaskCounter({ commit }) {
    commit('incrementTaskCounter')
  },
  async taskResponseHandler({ state, rootState, dispatch }, userInput) {
    if (rootState.formFlow.dataDestination === 'client') {
      _handleStoringPayloadInTempStorage({ dispatch, userInput })
      userInput = {}
    }

    try {
      await makeRequest({
        method: 'POST',
        endpoint: '/task/message',
        data: {
          taskId: state.taskId,
          userInput
        }
      })
    } catch (error) {
      console.error(new ApiError(error.message, error.response))
    }

    await dispatch('formFlow/resetDataDestination', undefined, { root: true })
  },
  updateTaskPrompts({ commit }, taskPrompts) {
    commit('setTaskPrompts', taskPrompts)
  },
  updateRecoveryFlow({ commit }, flow) {
    commit('setRecoveryFlow', flow)
  },
  updateTaskFailReason({ commit }, failReason) {
    commit('setTaskFailReason', failReason)
  },
  resetRecoveryFlow({ commit }) {
    commit('setRecoveryFlow', {
      id: '',
      sections: []
    })
  },
  resetTaskIdToConvert({ commit }) {
    commit('setTaskIdToConvert', undefined)
  },
  startTaskTimer({ commit }) {
    commit('setTaskTimer', createTaskTimer())
  },
  startWaitFulfillmentTimer({ commit }) {
    commit('setFulfillmentWaitTimer', createFulfillmentTimer())
  },
  clearAllTaskTimers({ commit }) {
    commit('clearAllTaskTimers')
  },
  updateHasRequestedDataFromCustomer: ({ commit }, hasRequestedData) => {
    commit('setHasRequestedDataFromCustomer', hasRequestedData)
  },
  startTaskCountdown({ commit, state, rootState }) {
    const durationMilliseconds = get(
      rootState.company.activeConnector,
      'taskAuthenticationDurationStatistics.mean.milliseconds',
      60000
    )

    let currentCountdown =
      state.taskCountdown.timer > 0
        ? state.taskCountdown.timer
        : durationMilliseconds / 1000

    commit(
      'setTaskCountdownInterval',
      setInterval(() => {
        if (currentCountdown >= 1) {
          commit('setCurrentTaskCountdownTime', (currentCountdown -= 1))
        } else {
          fadeOutTaskCountdown()
          clearInterval(state.taskCountdown.interval)
        }
      }, 1000)
    )
  },
  pauseTaskCountdown({ state }) {
    clearInterval(state.taskCountdown.interval)
  },
  resumeTaskCountdown({ dispatch }) {
    dispatch('startTaskCountdown')
  },
  clearTaskCountdown({ commit, state }) {
    clearInterval(state.taskCountdown.interval)
    commit('clearTaskCountdownTimer')
  },
  resetTaskId({ commit }) {
    commit('setCurrentTaskId', undefined)
  },
  async showUplink({}, taskId) {
    try {
      await makeRequest({
        method: 'POST',
        endpoint: `/task/show-uplink`,
        timeout: 30000,
        data: {
          taskId
        }
      })
    } catch (error) {
      console.error(new ApiError(error.message, error.response))
      throw error
    }
  }
}

function _handleStoringPayloadInTempStorage({ dispatch, userInput }) {
  Object.keys(userInput).forEach((inputKey) =>
    Object.keys(userInput[inputKey]).forEach((dataKey) => {
      if (userInput[inputKey][dataKey]?.value) {
        dispatch(
          'tempStorage/addSensitiveData',
          { key: dataKey, value: userInput[inputKey][dataKey].value },
          { root: true }
        )
      }
    })
  )
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
