import { PAY_LINK_USER_STATUS } from '@/util/constants'

export const switchHome = {
  [PAY_LINK_USER_STATUS.SELECTING]: {
    title: 'Comienza a conectar sus pagos',
    subtitle: `Esto es lo que has seleccionado.`
  },
  [PAY_LINK_USER_STATUS.IN_PROGRESS]: {
    title: 'Bien hecho hasta ahora',
    subtitle: `Aquí es donde lo dejo.`
  },
  [PAY_LINK_USER_STATUS.FINISHED]: {
    title: '¡Bien hecho!',
    subtitle: `Ha terminado conectando sus pagos.`
  },
  linkAnotherPayment: 'Conecta otro pago',
  selectMorePayments: 'Selecciona mas pagos',
  linked: 'Conectado'
}
