import { store } from '@/store'
import {
  USER_MESSAGE_TYPES,
  TASK_WORKFLOW_VIEW,
  TASK_WORKFLOW_RESULT,
  PAGES,
  SDK_EVENT_TYPES,
  HANDOFF_EVENTS,
  REQUEST_TYPES,
  MODAL_VIEW,
  AUTHENTICATION_METHODS
} from '@/util/constants'
import Ably from '@/plugins/ably'
import Analytics from '@/plugins/analytics'
import {
  finishTaskWorkflowAnimation,
  pauseProgressAnimation,
  completeProgressAnimation,
  startTaskViewChangeFromFinish,
  finishTaskViewChangeFromFinish,
  presentOptionToExit,
  fadeOutTaskCountdown
} from '@/util/animation'
import { completeProgressStep } from '@/util/progress/progress-actions'
import { shouldUseHandoff, appClose } from '@/util/app-event'
import { haptics } from '@/util/client-side-automation'
import {
  AUTHENTICATION_EVENTS,
  authenticationService
} from '@/machines/authentication'
import { stopOpenReplayDeprecated } from '@/util/client-side-automation/script-injections'
import RPC from '@/plugins/rpc'
import { stopOpenReplay } from '@/util/client-side-automation/page-scripts'
import { subscribeToTaskWorkflowOrchestratorEvents } from '@/util/realtime-helpers/task-workflow-orchestrator'

export async function subscribeRealtimeTaskWorkflowChannels({
  taskWorkflowId,
  userId,
  subscribeToRPC
}) {
  if (store.getters['main/isSerialProcessingMode']) {
    Ably.get()
      .channels.get(`${userId}:presence:${taskWorkflowId}`)
      .presence.enter()

    const channel = Ably.get().channels.get(
      `${userId}:task-workflow:${taskWorkflowId}`,
      { params: { rewind: '2m' } }
    )

    _bindToUserMessageEvents({ channel, userId, taskWorkflowId })
  } else if (
    store.getters['main/isConcurrentProcessingMode'] &&
    store.getters['taskWorkflow/authenticationMethod'] !==
      AUTHENTICATION_METHODS.UPLINK
  ) {
    const analytics = Analytics.get()

    // These subscriptions are unsubscribed from in the TaskProgress component
    // They're subscribed here to avoid race conditions that occur due to how startTaskFormBypass routes users to the TaskProgress component before the taskWorkflowId is set
    subscribeToTaskWorkflowOrchestratorEvents({
      analytics,
      store,
      taskWorkflowId
    })
  }

  if (subscribeToRPC) {
    await RPC.initialize(`task-workflow:rpc:${userId}:${taskWorkflowId}`)
  }
}

export function unsubscribeFromTaskEvents({ userId, taskWorkflowId }) {
  Ably.get()
    .channels.get(`${userId}:presence:${taskWorkflowId}`)
    ?.presence.leave()

  Ably.get().channels.get(`${userId}:task-workflow:${taskWorkflowId}`)?.detach()
}

const userMessageHandlerConfig = {
  [USER_MESSAGE_TYPES.GATHER_REQUEST]: _handleMfa,
  [USER_MESSAGE_TYPES.SMART_AUTH_DATA_REQUEST]: _handleSmartAuthDataRequest,
  [USER_MESSAGE_TYPES.AUTHENTICATION_SUCCESSFUL]:
    _handleAuthenticationSuccessful,
  [USER_MESSAGE_TYPES.AUTHENTICATION_FAILED]: _handleAuthenticationFailed,
  [USER_MESSAGE_TYPES.FRACTIONAL_DEPOSIT_ERROR]: _handleFractionalDepositError,
  [USER_MESSAGE_TYPES.MANUAL_MODE]: _handleManualMode,
  [USER_MESSAGE_TYPES.TASK_CREATED]: _handleTaskCreated,
  [USER_MESSAGE_TYPES.TASK_COMPLETED]: _handleTaskCompleted,
  [USER_MESSAGE_TYPES.TASK_FAILED]: _handleTaskFailed,
  [USER_MESSAGE_TYPES.USER_NOTIFICATION]: _handleUserNotification,
  [USER_MESSAGE_TYPES.TASK_WORKFLOW_FINISHED]: _handleTaskWorkflowFinished,
  [USER_MESSAGE_TYPES.EXTERNAL_LOGIN_RECOVERY]: _handleExternalLoginRecovery,
  [USER_MESSAGE_TYPES.INITIALIZE_ADP_EDDE_SDK]: _handleInitializeAdpEddeSdk,
  [USER_MESSAGE_TYPES.DATA_VERIFICATION]: _handleDataVerification
}

function _bindToUserMessageEvents({ channel, userId, taskWorkflowId }) {
  Object.entries(userMessageHandlerConfig).forEach(
    ([messageType, messageHandler]) =>
      channel.subscribe(messageType, async (payload) => {
        messageHandler({ payload: payload.data, userId, taskWorkflowId })
      })
  )
}

async function _handleMfa({ payload }) {
  await _handleGatherRequest({
    payload,
    requestType: REQUEST_TYPES.MFA
  })
}

async function _handleSmartAuthDataRequest({ payload }) {
  await store.dispatch('task/pauseTaskCountdown')
  await store.dispatch('task/clearAllTaskTimers')
  await store.dispatch('messaging/resetCustomMessaging')
  await store.dispatch('smartAuth/updateRequestData', payload)
  pauseProgressAnimation()

  if (payload.exceptionMessage) {
    await store.dispatch(
      'smartAuth/updateExceptionMessage',
      payload.exceptionMessage
    )
    authenticationService.send(AUTHENTICATION_EVENTS.SMART_AUTH_FINISHED)
  } else {
    authenticationService.send(AUTHENTICATION_EVENTS.SWITCH_METHOD)
  }

  await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.LOGIN
  })
}

async function _handleExternalLoginRecovery({ payload: { sections } = {} }) {
  await _handleGatherRequest({
    payload: {
      sections: sections ?? [
        {
          elements: [
            {
              title: store.getters['i18n/phrases'].externalLoginRecovery.title,
              subtitle:
                store.getters['i18n/phrases'].externalLoginRecovery.subtitle,
              isOptional: true,
              buttonText:
                store.getters['i18n/phrases'].externalLoginRecovery.buttonText
            },
            {
              title: store.getters[
                'i18n/phrases'
              ].externalLoginRecovery.newPasswordTitle({
                connector: store.state.company?.activeConnector?.name
              }),
              component: 'input',
              type: 'text',
              placeholder:
                store.getters['i18n/phrases'].externalLoginRecovery.password,
              name: 'password'
            }
          ]
        }
      ]
    },
    requestType: REQUEST_TYPES.EXTERNAL_LOGIN_RECOVERY
  })
}

async function _handleAuthenticationSuccessful({ payload: authPayload }) {
  const analyticsInstance = Analytics.get()

  fadeOutTaskCountdown()
  store.dispatch('task/clearAllTaskTimers')
  store.dispatch('task/clearTaskCountdown')

  analyticsInstance.track({ event: `Viewed ${PAGES.AUTHENTICATION_SUCCESS}` })

  // If the user is looking at the "Keep waiting" screen because the task went into manual mode
  if (
    store.state.taskWorkflow.taskWorkflowState.view ===
    TASK_WORKFLOW_VIEW.FINISHED
  ) {
    await startTaskViewChangeFromFinish()
    _setAuthToFinishState(authPayload)
    finishTaskViewChangeFromFinish()
  } else {
    if (!store.getters['progress/isAuthenticated']) {
      completeProgressStep()
    }

    // Don't present the option to exit when running automation on the user's device
    if (!store.getters['userDeviceAutomation/usingUserAutomatedDevice']) {
      presentOptionToExit()
    }
  }
}

function _setAuthToFinishState(authPayload) {
  store.commit('task/setAuthPayload', authPayload)
  store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.FINISHED,
    result: TASK_WORKFLOW_RESULT.COMPLETED
  })
}

async function _handleAuthenticationFailed({
  userId,
  taskWorkflowId,
  payload
}) {
  const analyticsInstance = Analytics.get()

  unsubscribeFromTaskEvents({ userId, taskWorkflowId })

  store.dispatch('task/clearAllTaskTimers')
  store.dispatch('task/clearTaskCountdown')
  store.dispatch('messaging/resetCustomMessaging')

  haptics.notifyError()

  if (payload) {
    store.dispatch('messaging/updateCustomMessaging', {
      customFailureMessage: payload.customFailureMessage,
      customFailureTitle: payload.customFailureTitle
    })
    store.dispatch('task/updateTaskFailReason', payload.failReason)
  }

  await completeProgressAnimation()
  store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.FINISHED,
    result: TASK_WORKFLOW_RESULT.FAILED
  })

  finishTaskWorkflowAnimation()
  analyticsInstance.track({ event: `Viewed ${PAGES.AUTHENTICATION_FAILED}` })
}

function _handleFractionalDepositError({ payload }) {
  store.dispatch('task/pauseTaskCountdown')
  store.dispatch('task/clearAllTaskTimers')
  store.dispatch('distribution/updateFractionalError', payload)
  store.dispatch('distribution/resetDistributionSettings')
  store.dispatch('distribution/resetNewDistributionSettings')
  store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.FRACTIONAL_DEPOSIT_ERROR,
    result: TASK_WORKFLOW_RESULT.FAILED
  })

  pauseProgressAnimation()
}

async function _handleManualMode() {
  const analyticsInstance = Analytics.get()
  const status = _determineFinishStatus()

  await completeProgressAnimation()

  // If we have had successful authentication and the task
  // goes into manual mode, we want to send the user to the
  // finish screen with a success status
  if (shouldUseHandoff({ store, handoffEvent: HANDOFF_EVENTS.HIGH_LATENCY })) {
    appClose({
      store,
      eventType: SDK_EVENT_TYPES.CLOSE,
      handoffEvent: HANDOFF_EVENTS.HIGH_LATENCY
    })
  } else {
    store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.FINISHED,
      result: status
    })
    store.dispatch('task/clearAllTaskTimers')
    store.dispatch('task/clearTaskCountdown')

    finishTaskWorkflowAnimation()
  }

  analyticsInstance.track({
    event: `Viewed ${PAGES.AUTHENTICATION_PAUSED}`
  })
}

function _handleTaskCreated({ payload }) {
  if (payload) {
    store.dispatch('task/updateCurrentTaskId', {
      taskId: payload.taskId
    })
  }
}

function _handleTaskCompleted({ payload: { uplinkPageHandle } }) {
  completeProgressStep()

  // Stop Open Replay on Uplink tasks
  _stopOpenReplay({ store, uplinkPageHandle })
}

async function _handleTaskFailed({ userId, taskWorkflowId, payload }) {
  const analyticsInstance = Analytics.get()

  unsubscribeFromTaskEvents({ userId, taskWorkflowId })

  // Stop Open Replay on Uplink tasks
  _stopOpenReplay({ store, uplinkPageHandle: payload.uplinkPageHandle })

  store.dispatch('task/clearAllTaskTimers')
  store.dispatch('task/clearTaskCountdown')
  store.dispatch('messaging/resetCustomMessaging')
  store.dispatch('progress/clearHookAnimationTimer')

  if (payload) {
    store.dispatch('messaging/updateCustomMessaging', {
      customFailureMessage: payload.customFailureMessage,
      customFailureTitle: payload.customFailureTitle
    })
    store.dispatch('task/updateTaskFailReason', payload.failReason)
  }

  await completeProgressAnimation()
  store.dispatch('taskWorkflow/updateTaskWorkflowId', undefined)
  store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.FINISHED,
    result: TASK_WORKFLOW_RESULT.FAILED
  })

  finishTaskWorkflowAnimation()

  analyticsInstance.track({
    event: `Viewed ${PAGES.TASK_FAILED}`,
    payload: {
      failReason: payload.failReason
    }
  })
}

async function _handleUserNotification(options) {
  store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.FINISHED,
    result: TASK_WORKFLOW_RESULT.TASK_RESULT_INFO,
    message: options.payload.message,
    title: options.payload.title
  })
  store.dispatch('task/clearAllTaskTimers')
  store.dispatch('task/clearTaskCountdown')
  pauseProgressAnimation()
}

async function _handleDataVerification({ payload }) {
  store.dispatch('user/updateEmploymentData', payload?.outputs)
  store.dispatch('taskWorkflow/updateTaskWorkflowState', {
    view: TASK_WORKFLOW_VIEW.DATA_VERIFICATION,
    result: TASK_WORKFLOW_RESULT.PENDING
  })
  haptics.notifySuccess()
}

async function _handleTaskWorkflowFinished({
  userId,
  taskWorkflowId,
  payload
}) {
  unsubscribeFromTaskEvents({ userId, taskWorkflowId })

  const productState = payload?.productState?.state

  if (productState === TASK_WORKFLOW_RESULT.FAILED) {
    store.dispatch(
      'taskWorkflow/updateTaskWorkflowFailReason',
      payload.productState?.failReason
    )
    await completeProgressAnimation()
    store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.FINISHED,
      result: TASK_WORKFLOW_RESULT.FAILED
    })
    finishTaskWorkflowAnimation()
  } else if (productState === TASK_WORKFLOW_RESULT.COMPLETED) {
    store.dispatch('messaging/resetCustomMessaging')

    if (
      shouldUseHandoff({
        store,
        handoffEvent: HANDOFF_EVENTS.AUTHENTICATION_SUCCESS
      })
    ) {
      appClose({
        store,
        eventType: SDK_EVENT_TYPES.FINISH,
        handoffEvent: HANDOFF_EVENTS.AUTHENTICATION_SUCCESS
      })
    } else {
      store.dispatch('taskWorkflow/updateTaskWorkflowState', {
        view: TASK_WORKFLOW_VIEW.FINISHED,
        result: TASK_WORKFLOW_RESULT.COMPLETED
      })
      haptics.notifySuccess()
      finishTaskWorkflowAnimation()
    }
  }
}

async function _stopOpenReplay({ store, uplinkPageHandle }) {
  if (await store.getters['userDeviceAutomation/useMuppet']) {
    const page = await store.dispatch(
      'userDeviceAutomation/getPage',
      uplinkPageHandle
    )
    await page.evaluate(stopOpenReplay)
  }
  // @DEPRECATED
  else {
    stopOpenReplayDeprecated({ store })
  }
}

function _determineFinishStatus() {
  // Check if authentication progress step is complete
  if (store.getters['progress/isAuthenticated']) {
    return TASK_WORKFLOW_RESULT.ONLY_AUTH_COMPLETED
  } else {
    return TASK_WORKFLOW_RESULT.FULFILLMENT_AVAILABLE
  }
}

function _hasFulfillmentStatus() {
  return (
    store.state.taskWorkflow.taskWorkflowState.result ===
    TASK_WORKFLOW_RESULT.FULFILLMENT_AVAILABLE
  )
}

async function _handleGatherRequest({ payload, requestType }) {
  store.dispatch('task/pauseTaskCountdown')
  store.dispatch('task/clearAllTaskTimers')
  store.dispatch('task/updateTaskPrompts', {
    sections: payload.sections,
    requestType
  })
  store.dispatch('messaging/resetCustomMessaging')

  // Check for customFailureTitle. For now this means to retry MFA.
  if (payload.customFailureTitle) {
    store.dispatch('messaging/updateCustomMessaging', {
      customFailureMessage: payload.customFailureMessage,
      customFailureTitle: payload.customFailureTitle
    })
    store.dispatch('taskWorkflow/updateTaskWorkflowState', {
      view: TASK_WORKFLOW_VIEW.FINISHED,
      result: TASK_WORKFLOW_RESULT.MFA_RETRY
    })

    finishTaskWorkflowAnimation()
  } else {
    await store.dispatch(
      'formFlow/updateDataDestination',
      payload.dataDestination || 'server'
    )

    if (_hasFulfillmentStatus()) {
      await startTaskViewChangeFromFinish()
      store.dispatch('taskWorkflow/updateTaskWorkflowState', {
        view: TASK_WORKFLOW_VIEW.INTERRUPT
      })
      finishTaskViewChangeFromFinish()
    } else {
      pauseProgressAnimation()
      await store.dispatch('taskWorkflow/updateTaskWorkflowState', {
        view: TASK_WORKFLOW_VIEW.INTERRUPT
      })
    }
  }
}

async function _handleInitializeAdpEddeSdk({ payload }) {
  await store.dispatch('modal/openModal', {
    view: MODAL_VIEW.ADP_EDDE_SDK,
    data: payload
  })
}
