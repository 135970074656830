export const taskCompleted = {
  allDoneHere: 'All done here!',
  continueBackOnOtherDevice: 'Continue back on your web browser.',
  messageDistributionUpdate: 'Updates may take 1-2 pay cycles to take effect.',
  linkAnotherGigAccount: 'Connect another gig account',
  linkAnotherAccount: 'Set up another account',
  messageOnlyAuthCompletedTitle: `Nice work! Now it's our turn`,
  messageOnlyAuthCompletedDeposit: `We just need a moment to finish your direct deposit update, but there's no need for you to hang around.`,
  messageOnlyAuthCompletedVerify: `We just need a moment to finish linking your payroll, but there's no need for you to hang around.`,
  messageOnlyAuthCompletedWithhold: `We just need a moment to finish your withholdings update, but there's no need for you to hang around.`,
  signInto: 'Sign in to',
  tryAgain: 'Try again',
  messageLong: 'Authentication is taking longer than expected.',
  keepWaiting: 'Keep waiting',
  messageWait:
    "If you'd like, you can leave and we will update you on the progress.",
  messageContinueProcess:
    'We will continue to process your request, and update you on the outcome.',
  messageAllSet: "You're all set!",
  gigSuccessTitle: ({ customerName, connectorName }) =>
    `Your ${connectorName} direct deposit is now updated to ${customerName}`,
  loginWithYourCredentials: 'Login with your credentials',
  messageNotifiedComplete:
    "You'll be notified when the process is complete, or if your help is needed",
  messageDepositTaskCompleted:
    'Your direct deposit has been updated successfully!',
  messageReadDataTaskCompleted: ({ customerName }) =>
    `Your payroll has been securely shared with ${customerName}.`,
  messageAuthenticationSuccessful: 'Authentication successful.',
  messageCompleteDescriptionDave: `Nice work logging in. Now it's our turn - we'll get your direct deposit updated in just a few moments. Please note that this may take up to 5 minutes.`,
  tax: {
    completed: {
      title: `You're all set!`,
      description: `We've gathered your documents required for tax filing.`,
      actionItems: {
        connectAnother: 'Connect another account'
      }
    }
  }
}
