export const progress = {
  connection: ({ name }) => `Connecting to ${name}`,
  titleAuthentication: 'Verify your credentials',
  titleDeposit: 'Updating your deposit',
  titleVerify: 'Verifying your employment',
  titleWithhold: 'Updating your withholdings',
  titleRecoveryFind: 'Finding your account',
  titleRecoveryReset: 'Updating your credentials',
  tax: {
    connection: ({ connectorName }) => `Connecting to ${connectorName}`,
    verify: 'Verifying your credentials',
    collect: 'Collecting documents',
    pull: 'Gathering tax documents'
  }
}
