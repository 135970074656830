/* eslint-disable no-empty-pattern */
import { makeRequest } from '@/util/requests'
import {
  PRODUCTS,
  TASK_WORKFLOW_VIEW,
  TASK_WORKFLOW_TYPES,
  PAGE_TRANSITIONS,
  AUTOMATION_DEVICES,
  AUTHENTICATION_METHODS
} from '@/util/constants'
import { get, last } from 'lodash-es'
import { taxTaskIncludesW2s } from '@/util/task-workflow'

const state = {
  taskWorkflowId: undefined,
  taskWorkflowState: {},
  taskWorkflowFailReason: undefined,
  taskWorkflowNavigation: {
    history: [],
    transition: PAGE_TRANSITIONS.SLIDE_LEFT
  },
  taskWorkflowBranding: {
    background: undefined,
    foreground: undefined
  },
  tasks: [],
  taskWorkflowType: TASK_WORKFLOW_TYPES.CUSTOM,
  employeeDirectory: undefined,
  preCoAuthTaskWorkflowBranding: {
    background: undefined,
    foreground: undefined
  },
  showTaskWorkflowBrandingHeader: true
}

const getters = {
  products: (state) => {
    return state.tasks
      .flatMap((task) => [task.product])
      .filter((product) => product)
  },
  productsIncludeDeposit: (_, getters) => {
    return getters.products.includes(PRODUCTS.DEPOSIT)
  },
  productsIncludeVerify: (_, getters) => {
    return getters.products.includes(PRODUCTS.VERIFY)
  },
  productsIncludeSwitch: (_, getters) => {
    return getters.products.includes(PRODUCTS.SWITCH)
  },
  firstProduct: (_, getters) => {
    return getters?.products[0]
  },
  taskWorkflowResult: (state) => {
    return state.taskWorkflowState?.result
  },
  taskWorkflowType: (state) => {
    return state.taskWorkflowType
  },
  taskWorkflowFailReason: (state) => {
    return state.taskWorkflowFailReason
  },
  taskWorkflowTypeIsTax: (state) => {
    return state.taskWorkflowType === TASK_WORKFLOW_TYPES.TAX
  },
  taskWorkflowTypeIsTaxWithW2s: (state) => {
    return (
      state.taskWorkflowType === TASK_WORKFLOW_TYPES.TAX &&
      taxTaskIncludesW2s({ tasks: state.tasks })
    )
  },
  taskWorkflowTypeIsManualFallback: (state) => {
    return state.taskWorkflowType === TASK_WORKFLOW_TYPES.MANUAL_FALLBACK
  },
  canGoBackInHistory: (state) =>
    state.taskWorkflowNavigation.history.length > 1,
  rootTaskView: (state) => get(state.taskWorkflowNavigation, 'history.0.view'),
  hasBackgroundLogo: (state) => !!state.taskWorkflowBranding.background,

  automationDevice: (state, getters, rootState, rootGetters) => {
    return rootGetters['userDeviceAutomation/usingUserAutomatedDevice'] &&
      !rootGetters['task/isInLoginRecovery']
      ? AUTOMATION_DEVICES.USER
      : AUTOMATION_DEVICES.CLOUD
  },
  authenticationMethod: (state, getters, rootState, rootGetters) => {
    const useUplink =
      getters.automationDevice === AUTOMATION_DEVICES.USER &&
      !rootGetters['company/useCoAuthConnector']

    if (useUplink) {
      return AUTHENTICATION_METHODS.UPLINK
    } else if (rootGetters['company/useCoAuthConnector']) {
      return AUTHENTICATION_METHODS.CO_AUTH
    } else if (rootGetters['company/usesSmartAuth']) {
      return AUTHENTICATION_METHODS.SMART_AUTH
    } else {
      return AUTHENTICATION_METHODS.STANDARD_AUTH
    }
  }
}

const actions = {
  updateTaskWorkflowTasks({ commit }, tasks) {
    commit('setWorkflowTasks', tasks)
  },
  updateTaskWorkflowType({ commit }, taskWorkflowType) {
    commit('setTaskWorkflowType', taskWorkflowType)
  },
  updateTaskWorkflowId({ commit }, taskWorkflowId) {
    commit('setTaskWorkflowId', taskWorkflowId)
  },
  updateTaskWorkflowFailReason({ commit }, failReason) {
    commit('setTaskWorkflowFailReason', failReason)
  },
  async resumeTaskWorkflow({ dispatch, state }) {
    await dispatch('task/startTaskCountdown', {}, { root: true })
    await makeRequest({
      method: 'POST',
      endpoint: `/task-workflow/${state.taskWorkflowId}/resume`,
      timeout: 30000
    })
  },
  async updateTaskWorkflowState({ commit }, newTaskWorkflowState) {
    await commit('addTaskWorkflowNavigationHistory', newTaskWorkflowState)
    await commit('setTaskWorkflowState', newTaskWorkflowState)
  },
  resetTaskWorkflowState(
    { commit },
    { initialView = TASK_WORKFLOW_VIEW.LOGIN }
  ) {
    const taskWorkflowState = {
      view: initialView,
      result: undefined
    }
    commit('setTaskWorkflowNavigation', {
      history: [taskWorkflowState],
      transition: PAGE_TRANSITIONS.SLIDE_LEFT
    })
    commit('setTaskWorkflowState', taskWorkflowState)
  },
  hideTaskWorkflowState({ commit }) {
    commit('setTaskWorkflowState', {
      view: undefined,
      result: undefined
    })
  },
  goBackInHistory({ commit }) {
    commit('popTaskWorkflowNavigationHistory')
  },
  updateTaskWorkflowBranding({ commit }, taskWorkflowBranding) {
    commit('setTaskWorkflowBranding', taskWorkflowBranding)
  },
  updateTaskWorkflowBrandingForeground({ state, commit }, foreground) {
    commit('setTaskWorkflowBranding', {
      ...state.taskWorkflowBranding,
      foreground
    })
  },
  clearTaskWorkflowBranding({ commit }) {
    commit('setTaskWorkflowBranding', {
      foreground: undefined,
      background: undefined
    })
  },
  clearTaskWorkflowBrandingBackground({ state, commit }) {
    commit('setTaskWorkflowBranding', {
      ...state.taskWorkflowBranding,
      background: undefined
    })
  },
  resetTaskWorkflowId({ commit }) {
    commit('setTaskWorkflowId', undefined)
  },
  resetTaskWorkflowFailReason({ commit }) {
    commit('setTaskWorkflowFailReason', undefined)
  },
  updateEmployeeDirectory({ commit }, employees) {
    commit('setEmployeeDirectory', employees)
  },
  preCoAuthBackup({ commit, state }) {
    commit('setPreCoauthTaskWorkflowBranding', state.taskWorkflowBranding)
  },
  preCoAuthRestore({ commit, state }) {
    commit('setTaskWorkflowBranding', state.preCoAuthTaskWorkflowBranding)
  },
  updateShowTaskWorkflowBrandingHeader(
    { commit },
    showTaskWorkflowBrandingHeader
  ) {
    commit('setShowTaskWorkflowBrandingHeader', showTaskWorkflowBrandingHeader)
  }
}

const mutations = {
  setWorkflowTasks: (state, tasks) => {
    state.tasks = tasks
  },
  setTaskWorkflowType: (state, taskWorkflowType) => {
    state.taskWorkflowType = taskWorkflowType
  },
  setTaskWorkflowId: (state, taskWorkflowId) => {
    state.taskWorkflowId = taskWorkflowId

    // Used by Cypress tests for debugging
    window.taskWorkflowId = taskWorkflowId
  },
  setTaskWorkflowFailReason: (state, failReason) => {
    state.taskWorkflowFailReason = failReason
  },
  setTaskWorkflowState: (state, taskWorkflowState) => {
    state.taskWorkflowState = taskWorkflowState
  },
  setTaskWorkflowNavigation: (state, taskWorkflowNavigation) => {
    state.taskWorkflowNavigation = taskWorkflowNavigation
  },
  setTaskWorkflowBranding: (state, taskWorkflowBranding) => {
    state.taskWorkflowBranding = taskWorkflowBranding
  },
  addTaskWorkflowNavigationHistory: (state, taskWorkflowState) => {
    state.taskWorkflowNavigation.transition = PAGE_TRANSITIONS.SLIDE_LEFT
    state.taskWorkflowNavigation.history.push(taskWorkflowState)
  },
  popTaskWorkflowNavigationHistory: (state) => {
    state.taskWorkflowNavigation.transition = PAGE_TRANSITIONS.SLIDE_RIGHT
    state.taskWorkflowNavigation.history.pop()
    state.taskWorkflowState = last(state.taskWorkflowNavigation.history)
  },
  setEmployeeDirectory: (state, employees) => {
    state.employeeDirectory = employees
  },
  setPreCoauthTaskWorkflowBranding: (state, taskWorkflowBranding) => {
    state.preCoAuthTaskWorkflowBranding = taskWorkflowBranding
  },
  setShowTaskWorkflowBrandingHeader: (
    state,
    showTaskWorkflowBrandingHeader
  ) => {
    state.showTaskWorkflowBrandingHeader = showTaskWorkflowBrandingHeader
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
