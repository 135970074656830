import { TASK_FAIL_REASON } from '@/util/constants'

export const errorMessage = {
  failedRequest: 'Solicitud incorrecta',
  expiredToken: 'Token caducado',
  expiredSession: 'Sesión expirada',
  expiredSessionDescription:
    'Su sesión ha expirado. Por favor, salga y vuelva a intentarlo.',
  invalidToken: 'No está autorizado para ver esta página.',
  taskUnauthorized: 'Tarea no autorizada',
  failedConfig: 'Configuración inválida.',
  reconnecting: 'Reconectando',
  connectionLost: 'Conexión perdida',
  [TASK_FAIL_REASON.BAD_CREDENTIALS]: {
    title: 'Credenciales inválidas',
    message:
      'Su nombre de usuario o contraseña es incorrecto. Por favor, inténtelo de nuevo.'
  },
  [TASK_FAIL_REASON.ACCOUNT_LOCKOUT]: {
    title: 'Cuenta bloqueada',
    message: 'Ha realizado demasiados intentos fallidos de inicio de sesión.'
  },
  [TASK_FAIL_REASON.ACCOUNT_SETUP_INCOMPLETE]: {
    title: 'Configuración de cuenta incompleta',
    message:
      'Antes de poder conectar su cuenta de nómina, debe iniciar sesión y completar la incorporación de empleados.'
  },
  [TASK_FAIL_REASON.CONNECTION_ERROR]: {
    title: 'Problema de conexión',
    message:
      'A veces ocurren fallos en la red. Intentarlo de nuevo puede ayudar.'
  },
  [TASK_FAIL_REASON.SESSION_TIMEOUT]: {
    title: 'Sesión caducada',
    message: 'Intentar de nuevo probablemente solucionará el problema.'
  },
  [TASK_FAIL_REASON.PRODUCT_NOT_SUPPORTED]: {
    title: 'Actualización no soportada',
    message:
      'Asegúrese de que su cuenta esté completamente activa y que su empleador no haya restringido el acceso.'
  },
  [TASK_FAIL_REASON.DATA_VALIDATION_FAILED]: {
    title: `No pudimos localizar su información`,
    message:
      'Nos conectamos a su cuenta, pero no pudimos encontrar todos los datos requeridos.'
  },
  [TASK_FAIL_REASON.NO_DATA_FOUND]: {
    title: `No pudimos confirmar su cuenta`,
    message:
      'Faltan los datos de empleo de su cuenta. ¿Está seguro de que esta es su cuenta actual?'
  },
  [TASK_FAIL_REASON.UNKNOWN_FAILURE]: {
    title: 'Algo salió mal',
    message:
      'Nos encontramos con un error inesperado. Por favor, inténtelo de nuevo.'
  },
  [TASK_FAIL_REASON.WORK_STATUS_TERMINATED]: {
    title: 'Cuenta incorrecta',
    message:
      'Si está intentando iniciar sesión con su correo electrónico del trabajo, intente con su correo electrónico personal o restablezca su contraseña.'
  },
  [TASK_FAIL_REASON.ACCOUNT_UNUSABLE]: {
    title: 'Cuenta bancaria rechazada',
    message: 'Su cuenta de nómina rechazó agregar esta nueva cuenta bancaria.'
  },
  [TASK_FAIL_REASON.DISTRIBUTION_NOT_SUPPORTED]: {
    title: 'Monto del depósito rechazado',
    message:
      'Su cuenta no soporta el monto de depósito solicitado o hay cantidades de distribución en conflicto.'
  },
  [TASK_FAIL_REASON.ENROLLED_IN_PAYCARD]: {
    title: 'Lo sentimos, actualmente no soportamos tarjetas de pago',
    message:
      'Parece que su inscripción en la tarjeta de pago le impide recibir pagos mediante depósito directo en una cuenta bancaria.'
  },
  [TASK_FAIL_REASON.EXPIRED]: {
    title: 'Contraseña caducada',
    message: 'Cree una nueva contraseña para continuar.'
  },
  [TASK_FAIL_REASON.ROUTING_NUMBER_NOT_SUPPORTED]: {
    title: 'Número de ruta rechazado',
    message:
      'El sistema rechazó el número de ruta de su cuenta. Puede que necesite contactarlos para proceder.'
  },
  [TASK_FAIL_REASON.SYSTEM_UNAVAILABLE]: {
    title: 'Sistema no disponible',
    message: 'Por favor, inténtelo de nuevo más tarde.'
  },
  [TASK_FAIL_REASON.TRANSACTION_PENDING]: {
    title: 'Actualización pendiente',
    message:
      'Tiene una actualización en su cuenta que aún está en proceso. Por favor, inténtelo de nuevo más tarde.'
  },
  [TASK_FAIL_REASON.USER_DATA_REJECTED]: {
    title: 'Gracias por informarnos',
    message:
      'Como los datos parecen ser inexactos, no se compartirán. Puede intentar iniciar sesión con otro empleador y elegir otra opción para confirmar sus ingresos.'
  },
  failedWithholdNotSupported:
    'Debe proporcionar una cuenta vinculada para utilizar el producto de retención.'
}
