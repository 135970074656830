<template>
  <ModalContent>
    <ModalBody>
      <ModalContentWrap class="uplink-demo-content-wrap">
        <div class="browser-navbar">
          <button
            style="color: #117dff"
            class="content-only text-left"
            @click="exitAuth"
          >
            Done
          </button>
          <span
            class="d-flex justify-content-center align-items-center text-center"
          >
            <img
              alt="refresh"
              src="/img/icon-lock.svg"
              class="mr-1"
              style="width: 15px"
            />
            <span style="font-weight: 700">{{ location }}</span>
          </span>
          <span class="text-right">
            <img alt="refresh" src="/img/icon-ios-refresh.svg" />
          </span>
        </div>
        <transition name="fade">
          <div v-if="showLoading" class="line-loader"></div>
        </transition>
        <iframe
          v-if="showIframe"
          ref="iframe"
          title="Uplink"
          aria-label="Uplink Demo Modal"
          data-test-id="uplink-demo-modal"
          :src="url"
          :style="{ opacity: iframeLoaded ? 1 : 0 }"
          @load="iframeWasLoaded"
        />
        <Loading v-show="showLoading" />
      </ModalContentWrap>
    </ModalBody>
  </ModalContent>
</template>

<script>
import ModalContent from '@/components/Modal/ModalContent.vue'
import ModalContentWrap from '@/components/Modal/ModalContentWrap.vue'
import ModalBody from '@/components/Modal/ModalBody.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { demoAsIfAuthenticated } from '@/util/client-side-automation/sdk-automation-events'
import Loading from '@/components/Shared/Loading.vue'
import { backButtonHandler } from '@/util/back-button'

export default {
  name: 'UplinkDemoModal',
  components: { Loading, ModalBody, ModalContent, ModalContentWrap },
  data: () => ({
    showIframe: false,
    iframeLoaded: false,
    showLoading: true,
    authenticatedListener: undefined
  }),
  computed: {
    ...mapState('company', ['activeConnector']),
    ...mapState('taskWorkflow', ['taskWorkflowBranding']),
    ...mapGetters('company', ['brandColor']),
    ...mapGetters('userDeviceAutomation', ['parsedUserAutomationDeviceConfig']),
    location() {
      return new URL(this.parsedUserAutomationDeviceConfig.startURL).hostname
    },
    url() {
      const { name } = this.activeConnector
      const { url, logoBackgroundColor } = this.taskWorkflowBranding.foreground

      const logoColor = logoBackgroundColor.replace('#', '')
      const buttonColor = this.brandColor.replace('#', '')

      return `https://mocky.atomicfi.com/#/uplink?name=${name}&logo=${url}&logoColor=${logoColor}&buttonColor=${buttonColor}`
    }
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    ...mapActions('demo', ['updateUplinkDemoUsername']),
    iframeWasLoaded() {
      this.iframeLoaded = true
      this.showLoading = false
    },
    exitAuth() {
      this.closeModal()
      backButtonHandler()
    }
  },
  mounted() {
    setTimeout(() => {
      this.showIframe = true
    }, 1000)

    this.authenticatedListener = (message) => {
      if (message?.data?.event === 'uplink-authenticated') {
        this.updateUplinkDemoUsername(message.data.username)
        demoAsIfAuthenticated({ store: this.$store })
        this.closeModal()
      }
    }

    window.addEventListener('message', this.authenticatedListener)
  },
  unmounted() {
    window.removeEventListener('message', this.authenticatedListener)
  }
}
</script>

<style lang="scss" scoped>
.uplink-demo-content-wrap {
  // This modal content is an exception because it's emulating a browser.
  padding: 0;
}

.browser-navbar {
  height: 60px;
  width: 100%;
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  border-bottom: 2px solid var(--gray-200);
  z-index: 2;

  .dark & {
    color: var(--white);
    border-bottom-color: var(--gray-700);
  }

  button {
    font-size: 16px;
    transition: all 100ms linear;

    &:focus {
      opacity: 0.5;
    }
  }
}

.line-loader {
  height: 3px;
  width: 0;
  position: absolute;
  top: 58px;
  left: 0;
  background: var(--info-400);
  z-index: 3;
  animation: lineLoading 1s cubic-bezier(0.55, 0.905, 0.88, 0.185) 200ms
    forwards;
}

iframe {
  position: absolute;
  top: 60px;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 1;
  opacity: 0;
  transition: all linear 200ms;
}

@keyframes lineLoading {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
