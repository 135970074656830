export const ROUTES = {
  INITIALIZE: 'Initialize',
  WELCOME: 'Welcome',
  ACCOUNT: 'Account',
  ADD_CARD: 'AddCard',
  DEPOSIT_PRESELECTED_COMPANIES: 'DepositPreselectedCompanies',
  GENERATE_DEPOSIT_PDF: 'GenerateDepositPDF',
  SEARCH_COMPANY: 'SearchCompany',
  SEARCH_FRANCHISE: 'SearchFranchise',
  SEARCH_PAY_LINK: 'SearchPayLink',
  MINIMAL_SEARCH_COMPANY: 'MinimalSearchCompany',
  SEARCH_PAYROLL: 'SearchPayroll',
  MAINTENANCE: 'Maintenance',
  TASK: 'Task',
  TASK_HISTORY: 'TaskHistory',
  DISTRIBUTION: 'Distribution',
  REQUIRE_TERMS: 'RequireTerms',
  SWITCH_HOME: 'SwitchHome',
  SWITCH_WELCOME: 'SwitchWelcome'
}
