import { AUTHENTICATION_METHODS, TASK_WORKFLOW_VIEW } from '@/util/constants'
import {
  isRoutingNumberBlocked as isRoutingNumberBlockedUtil,
  isSystemAfterHours
} from '@/util/connector'
import {
  featureUniqueForOne,
  isWalmart,
  isSamsClub,
  isSpark
} from '@/util/customization'
import { userNeedsToAddCard as payLinkUserNeedsToAddCard } from '@/util/pay-link'

export const routeQueryDefinesInitialView = ({ route }) =>
  route?.query?.view ? route.query.view : null

export const canUseCoAuthConnector = ({ store }) =>
  store.getters['company/useCoAuthConnector'] ? TASK_WORKFLOW_VIEW.LOGIN : null

export const isAfterHoursForConnector = ({ store }) =>
  isSystemAfterHours(store.state.company?.activeConnector)
    ? TASK_WORKFLOW_VIEW.INTERSTITIAL.SYSTEM_AFTER_HOURS
    : null

export const isFirstSmartAuthDepositUser = ({ store }) =>
  store.getters['taskWorkflow/authenticationMethod'] ===
    AUTHENTICATION_METHODS.SMART_AUTH &&
  store.getters['smartAuth/isFirstUserConnecting'] &&
  store.getters['taskWorkflow/productsIncludeDeposit']
    ? TASK_WORKFLOW_VIEW.CONFIRM_DISTRIBUTION
    : null

export const isMaintenanceMode = ({ store }) =>
  _connectorIsInMaintenanceMode({ store }) ||
  _customerWantsConnectorInMaintenanceMode({ store })
    ? TASK_WORKFLOW_VIEW.INTERSTITIAL.MAINTENANCE_MODE
    : null

function _connectorIsInMaintenanceMode({ store }) {
  return store.state.company?.activeConnector?.maintenanceMode
}

function _customerWantsConnectorInMaintenanceMode({ store }) {
  return (
    store.state.user?.userData?.customer?.features?.maintenanceMode?.enabled &&
    store.state.user?.userData?.customer?.features?.maintenanceMode?.connectors.some(
      (connector) => connector._id === store.state.company?.activeConnector._id
    )
  )
}
export const isManualConnector = ({ store }) =>
  store.state.company?.manualDeposit?.enabled ? TASK_WORKFLOW_VIEW.MANUAL : null

export const isRoutingNumberBlocked = ({ store }) =>
  isRoutingNumberBlockedUtil({
    connector: store.state.company?.activeConnector,
    customer: store.state.user?.userData?.customer,
    account: store.state.user?.account
  })
    ? TASK_WORKFLOW_VIEW.INTERSTITIAL.ROUTING_NUMBER_BLOCKED
    : null

export const uplinkIsAvailable = ({ store }) =>
  store.getters['userDeviceAutomation/uplinkIsAvailable'] &&
  store.getters['company/connectorId']
    ? TASK_WORKFLOW_VIEW.LOGIN
    : null

export const isManualFallback = ({ store }) =>
  store.state.taskWorkflow.taskWorkflowState.view ===
  TASK_WORKFLOW_VIEW.INTERSTITIAL.MANUAL_FALLBACK
    ? TASK_WORKFLOW_VIEW.INTERSTITIAL.MANUAL_FALLBACK
    : null

export const isManualFallbackPrefilled = ({ store }) =>
  store.state.taskWorkflow.taskWorkflowState.view ===
  TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED
    ? TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED
    : null

export const isManualFallbackPrefilledSuccess = ({ store }) =>
  store.state.taskWorkflow.taskWorkflowState.view ===
  TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED_SUCCESS
    ? TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_PREFILLED_SUCCESS
    : null

export const isManualFallbackAccountRouting = ({ store }) =>
  store.state.taskWorkflow.taskWorkflowState.view ===
  TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_ACCOUNT_ROUTING
    ? TASK_WORKFLOW_VIEW.MANUAL_FALLBACK_ACCOUNT_ROUTING
    : null

export const userNeedsToAddCard = ({ store }) =>
  store.getters['main/isScopePayLink'] &&
  payLinkUserNeedsToAddCard({
    supportedPaymentMethodTypes:
      store.getters['payLink/supportedPaymentMethodTypes'],
    cardPaymentMethods: store.getters['user/cardPaymentMethods']
  })
    ? TASK_WORKFLOW_VIEW.INTERSTITIAL.ADD_CARD
    : null

export const isScopePayLink = ({ store }) =>
  store.getters['main/isScopePayLink']
    ? TASK_WORKFLOW_VIEW.INTERSTITIAL.PAYMENT_CHOOSER
    : null

export const useCustomManualInstructions = ({ store }) => {
  if (featureUniqueForOne({ customer: store.state.user.userData.customer })) {
    if (isWalmart({ company: store.state.company })) {
      return TASK_WORKFLOW_VIEW.MANUAL_WALMART
    } else if (isSamsClub({ company: store.state.company })) {
      return TASK_WORKFLOW_VIEW.MANUAL_WALMART
    } else if (isSpark({ company: store.state.company })) {
      return TASK_WORKFLOW_VIEW.MANUAL_SPARK
    }
  }
}
