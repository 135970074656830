export const exitModal = {
  generic: {
    tax: {
      title: 'Exit linking tax documents?',
      description:
        'Your progress will be lost and any data entered will be removed for your security.'
    },
    deposit: {
      title: 'Exit direct deposit setup?',
      description:
        'Your progress will be lost and any data entered will be removed for your security.'
    },
    verify: {
      title: 'Not ready to link your account?',
      description:
        'Your progress will be lost and any data entered will be removed for your security.'
    },
    switch: {
      title: 'Done linking payments?',
      description: 'Come back any time to link.'
    }
  },
  search: {
    title: `Can't find your employer or payroll provider?`,
    description:
      'See tips for finding your pay provider or set up direct deposit manually.',
    helperAction: 'How to find your employer/payroll'
  },
  login: {
    title: 'Having trouble signing in?',
    description:
      'Learn where to find your credentials or set up direct deposit manually.',
    helperAction: 'How to find your credentials'
  },
  distribution: {
    title: 'Not ready to update your direct deposit?',
    canEditTitle: 'Not sure how much to direct deposit?',
    description: {
      canChange:
        'Most people choose their entire paycheck, but you can also set a smaller amount.',
      noChange: 'Come back anytime to finish updating your direct deposit.'
    }
  },
  actionItems: {
    changeDepositAmount: 'Change deposit amount',
    setUpManually: 'Set up manually'
  }
}
