export const manualDepositInstructions = {
  accountInformationHiddenAriaLabel:
    'Account information; hidden for your protection.',
  accountInformationVisibleAriaLabel:
    'Account information; visible and can be copied to your clipboard.',
  accountInformationVisibleAriaLive:
    'Account information is now visible and can be copied.',
  accountNumber: 'Account number',
  copyWebsiteLink: 'Copy website link:',
  websiteLink: 'Account number',
  copied: 'Copied',
  copyAccountNumber: 'Copy account number.',
  copyRoutingNumber: 'Copy routing number.',
  disabledCopyButtonAriaLabel:
    'Proceed to the, show account number button, to copy account information.',
  routingNumber: 'Routing number',
  showAccountInformation: 'Show account number',
  updateYourDeposit: ({ name }) => `Update your ${name} deposit`
}
