import getOpenReplayScript from './libraries/openreplay.min'

const emptyFunction = async () => {}

function _wrapScriptInFunction(script = '') {
  return `async () => {
    ${script}
  }
  `
}

export const demoCredentialsDetectorScript = `
  window.demoInterval = setInterval(() => {
    if (window.location.href.includes('mocky')) {
      return
    }
  
    const demoCredentialsWereUsed = Array.from(document.querySelectorAll('input')).some(input =>
        input.value?.toLowerCase()?.includes('test-good') || input.value?.toLowerCase()?.includes('test-feels-so-good')
    )
    
    if (demoCredentialsWereUsed) {
      clearInterval(window.demoInterval)
      MuppetPage.dispatch({ type: 'demo-auth' })
    }
  }, 2000)
`

export function openReplayScriptFunction({ store }) {
  if (!store.getters['main/useUplinkOpenReplay']) {
    return emptyFunction
  }

  const userId = store.state.user.userData._id
  const connectorId = store.getters['company/connectorId']
  const companyId = store.getters['company/companyId']
  const capturePayload =
    store.state.experiment.ldFlags.captureUplinkOpenReplayPayload

  const openReplayScript = getOpenReplayScript({
    userId,
    openReplaySessionHash:
      store.state.userDeviceAutomation.activeSession.openReplaySessionHash,
    capturePayload
  })

  // TODO: Broadcast out a message to transact
  return _wrapScriptInFunction(`
    if (!window.OpenReplay) {      
      ${openReplayScript}

      setTimeout(async () => {
        let checks = 0
        
        while (checks < 50 && !window.OpenReplay?.app?.session?.getSessionHash()) {
          checks++
          await new Promise(resolve => setTimeout(resolve, 100))
        }

        window.OpenReplay?.setMetadata('CompanyID', '${companyId}')
        window.OpenReplay?.setMetadata('ConnectorID', '${connectorId}')
        
        console.log('Broadcast the hash back to Transact')
      }, 50)
    }
  `)
}

export const addPasswordManagerInputFunction = () => {
  if (!document.querySelector('[autocomplete="one-time-code"]')) {
    window.existingPasswordInput = document.querySelector(
      '#atomic-password-manager'
    )

    if (!window.existingPasswordInput) {
      window.existingPasswordInput = document.createElement('input')
      window.existingPasswordInput.id = 'atomic-password-manager'
      window.existingPasswordInput.type = 'password'
      window.existingPasswordInput.style.opacity = '0'
      window.existingPasswordInput.style.height = '0'
      window.existingPasswordInput.style.width = '0'
      document.body?.appendChild(window.existingPasswordInput)
    }
  }
}

export const setOpenReplayMetadata = ({ metadata }) => {
  Object.entries(metadata).forEach(([key, value]) => {
    window.OpenReplay?.setMetadata(key, value)
  })
}

export const stopOpenReplay = () => {
  window.OpenReplay?.stop()
}

export function requestInterceptionScript({ config }) {
  if (!config.interceptRequests) {
    return emptyFunction
  }

  return (requestHook, responseHook) => {
    if (!window.addedRequestInterceptor) {
      window.broadcastInterceptedRequest = (request) => {
        window.MuppetPage.dispatch({ type: 'request', request })
      }

      window.getLowerCasedHeaders = (headers = {}) => {
        let lowerCasedHeaders = {}

        Object.entries(headers).forEach(([key, value]) => {
          lowerCasedHeaders[key.toLowerCase()] = value
        })

        return lowerCasedHeaders
      }

      try {
        requestHook = eval(requestHook)
        responseHook = eval(responseHook)
      } catch (error) {
        console.error('Error loading interceptors', error)
      }

      window.originalFetch = window.fetch

      window.fetch = async (...args) => {
        const [resource, config] = args
        const isRequestObject = resource instanceof Request
        let interceptedRequestData = {}
        let interceptedResponseData = {}
        let requestBody
        let requestHeaders = {}
        let lowerCasedRequestHeaders

        try {
          const headers = isRequestObject ? resource.headers : config?.headers

          if (headers instanceof Headers) {
            headers.forEach((value, key) => {
              requestHeaders[key] = value
            })
          } else {
            requestHeaders = headers
          }

          lowerCasedRequestHeaders = window.getLowerCasedHeaders(requestHeaders)

          const body = isRequestObject
            ? await resource.clone().text()
            : config?.body
          requestBody =
            lowerCasedRequestHeaders['content-type']?.includes(
              'application/json'
            ) && typeof body === 'string'
              ? JSON.parse(body)
              : body
        } catch (error) {
          console.error('Error parsing fetch request', error)
        }

        try {
          interceptedRequestData =
            requestHook({
              url: isRequestObject ? resource.url : resource,
              method: isRequestObject ? resource.method : config?.method,
              requestBody,
              requestHeaders: lowerCasedRequestHeaders,
              requestType: 'fetch',
              rawRequest: resource instanceof Request ? resource : config
            }) ?? {}
        } catch (error) {
          console.error('Error running request interceptor', error)
        }

        const response = await window.originalFetch(resource, config)

        const responseHeaders = Object.fromEntries(response.headers)
        const lowerCasedResponseHeaders =
          window.getLowerCasedHeaders(responseHeaders)

        try {
          const responseCopy = response.clone() // body can only be read once

          const responseBody = lowerCasedResponseHeaders[
            'content-type'
          ]?.includes('application/json')
            ? await responseCopy.json()
            : await responseCopy.text()

          interceptedResponseData =
            responseHook({
              url: response.url,
              method: isRequestObject ? resource.method : config?.method,
              status: response.status,
              requestBody,
              responseBody,
              requestHeaders: lowerCasedRequestHeaders,
              responseHeaders: lowerCasedResponseHeaders,
              requestType: 'fetch',
              rawResponse: response
            }) ?? {}
        } catch (error) {
          console.error('Error running response interceptor', error)
        }

        window.broadcastInterceptedRequest({
          requestHeaders: lowerCasedRequestHeaders,
          responseHeaders: lowerCasedResponseHeaders,
          interceptedData: {
            ...interceptedRequestData,
            ...interceptedResponseData
          }
        })

        return response
      }

      const openCopy = XMLHttpRequest.prototype.open
      const sendCopy = XMLHttpRequest.prototype.send

      XMLHttpRequest.prototype.open = function (method, url) {
        this.url = url
        this.method = method

        this.addEventListener('load', function () {
          let interceptedResponseData

          const responseHeaders = Object.fromEntries(
            new Map(
              (this.getAllResponseHeaders() ?? '')
                .split('\\r\\n')
                .filter(Boolean)
                .map((header) => header.split(': '))
            )
          )

          const lowerCasedRequestHeaders = window.getLowerCasedHeaders(
            this.requestHeaders
          )
          const lowerCasedResponseHeaders =
            window.getLowerCasedHeaders(responseHeaders)

          try {
            const responseBody =
              lowerCasedResponseHeaders['content-type']?.includes(
                'application/json'
              ) && typeof this.response === 'string'
                ? JSON.parse(this.response)
                : this.response

            interceptedResponseData = responseHook({
              url: this.url,
              method: this.method,
              status: this.status,
              requestBody: this.requestBody,
              responseBody,
              requestHeaders: lowerCasedRequestHeaders,
              responseHeaders: lowerCasedResponseHeaders,
              requestType: 'xhr',
              rawResponse: this
            })
          } catch (error) {
            console.error('Error running response interceptor', error)
          }

          window.broadcastInterceptedRequest({
            requestHeaders: lowerCasedRequestHeaders,
            responseHeaders: lowerCasedResponseHeaders,
            interceptedData: interceptedResponseData ?? {}
          })
        })

        openCopy.apply(this, arguments)
      }

      XMLHttpRequest.prototype.wrappedSetRequestHeader =
        XMLHttpRequest.prototype.setRequestHeader

      XMLHttpRequest.prototype.setRequestHeader = function (header, value) {
        this.wrappedSetRequestHeader(header, value)

        this.requestHeaders = this.requestHeaders || {}
        this.requestHeaders[header.toLowerCase()] = value
      }

      XMLHttpRequest.prototype.send = function (body) {
        let interceptedRequestData

        try {
          const lowerCaseRequestHeaders = window.getLowerCasedHeaders(
            this.requestHeaders
          )
          this.requestBody =
            lowerCaseRequestHeaders['content-type']?.includes(
              'application/json'
            ) && typeof body === 'string'
              ? JSON.parse(body)
              : body

          interceptedRequestData = requestHook({
            url: this.url,
            method: this.method,
            requestBody: this.requestBody,
            requestHeaders: lowerCaseRequestHeaders,
            requestType: 'xhr',
            rawRequest: this
          })
        } catch (error) {
          console.error('Error running request interceptor', error)
        }

        window.broadcastInterceptedRequest({
          interceptedData: interceptedRequestData ?? {}
        })

        sendCopy.apply(this, arguments)
      }

      window.addedRequestInterceptor = true
    }
  }
}
