import {
  BILL_PAYMENT_METHOD_TYPES,
  PAGES,
  PAY_LINK_USER_STATUS,
  USER_COMPANY_SELECTION_STATUS,
  SELECTION_REMOVAL_STRATEGY,
  MODAL_VIEW
} from '@/util/constants'
import {
  determinePaymentMethodForConnector,
  getPaymentMethodType,
  getPaymentMethodAccountType
} from '@/util/pay-link'
import { negate } from 'lodash-es'

const state = () => ({
  isLoading: false,
  isRefreshingPaymentMethods: false,
  selections: [],
  selectionsToAdd: [],
  selectionsToRemove: [],
  paymentMethod: undefined
})

const getters = {
  visibleSelections(state) {
    return [...state.selections, ...state.selectionsToAdd]
  },
  activeSelection(state, getters, rootState, rootGetters) {
    return getters.visibleSelections.find(
      ({ company }) => company._id === rootGetters['company/companyId']
    )
  },
  supportedPaymentMethodTypes(state, getters, rootState) {
    const supportedTypes =
      rootState.company.connector?.capabilities?.supportedPaymentMethods
    return supportedTypes && supportedTypes.length
      ? supportedTypes
      : /**
         * Supported payment methods should be defined on connector, but in case
         * they aren't, we default all types to still allow the user to attempt
         * the task.
         */
        Object.values(BILL_PAYMENT_METHOD_TYPES)
  },
  companyIsSelected(state, getters) {
    return (company) => getters.visibleSelections.some(_companyMatches(company))
  },
  companySelectionStatus(state, getters) {
    return (company) =>
      getters.visibleSelections.find(_companyMatches(company))?.status
  },
  actionableSelections(state, getters) {
    const statusPriority = {
      [USER_COMPANY_SELECTION_STATUS.REQUIRES_USER_INPUT]: 1,
      [USER_COMPANY_SELECTION_STATUS.FAILED]: 2,
      [USER_COMPANY_SELECTION_STATUS.SELECTED]: 3
    }

    return getters.visibleSelections
      .filter(({ status }) =>
        [
          USER_COMPANY_SELECTION_STATUS.REQUIRES_USER_INPUT,
          USER_COMPANY_SELECTION_STATUS.FAILED,
          USER_COMPANY_SELECTION_STATUS.SELECTED
        ].includes(status)
      )
      .sort((a, b) => {
        // Compare status first
        if (statusPriority[a.status] !== statusPriority[b.status]) {
          return statusPriority[a.status] - statusPriority[b.status]
        }

        // If status is the same, compare createdAt
        return new Date(a.createdAt) - new Date(b.createdAt)
      })
  },
  selectedSelections(state, getters) {
    return getters.visibleSelections.filter(
      ({ status }) => status === USER_COMPANY_SELECTION_STATUS.SELECTED
    )
  },
  completedSelections(state, getters) {
    return getters.visibleSelections.filter(
      ({ status }) => status === USER_COMPANY_SELECTION_STATUS.COMPLETED
    )
  },
  inProgressSelections(state, getters) {
    return getters.visibleSelections.filter(
      ({ status }) => status === USER_COMPANY_SELECTION_STATUS.IN_PROGRESS
    )
  },
  nextSelection(state, getters) {
    return getters.actionableSelections[0]
  },
  userStatus(state, getters) {
    if (!getters.visibleSelections.length) {
      return PAY_LINK_USER_STATUS.NOT_STARTED
    }

    if (
      getters.visibleSelections.every(
        ({ status }) => status === USER_COMPANY_SELECTION_STATUS.SELECTED
      )
    ) {
      return PAY_LINK_USER_STATUS.SELECTING
    }

    if (
      getters.visibleSelections.every(
        ({ status }) => status === USER_COMPANY_SELECTION_STATUS.COMPLETED
      )
    ) {
      return PAY_LINK_USER_STATUS.FINISHED
    }

    return PAY_LINK_USER_STATUS.IN_PROGRESS
  }
}

const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setIsRefreshingPaymentMethods(state, isRefreshing) {
    state.isRefreshingPaymentMethods = isRefreshing
  },
  setSelections(state, selections) {
    state.selections = selections
  },
  setSelectionsToAdd(state, selections) {
    state.selectionsToAdd = selections
  },
  setSelectionsToRemove(state, selections) {
    state.selectionsToRemove = selections
  },
  setPaymentMethod(state, paymentMethod) {
    state.paymentMethod = paymentMethod
      ? {
          ...paymentMethod,
          accountType: getPaymentMethodAccountType(paymentMethod),
          type: getPaymentMethodType(paymentMethod)
        }
      : paymentMethod
  }
}

const createPayLinkActions = (payLinkService) => ({
  addSelection({ state, commit }, company) {
    commit('setSelectionsToAdd', [
      ...state.selectionsToAdd,
      {
        company,
        status: USER_COMPANY_SELECTION_STATUS.SELECTED
      }
    ])
  },
  updateSelections({ commit, state }, selections) {
    commit('setSelections', selections)
    // If a selection from selectionsToAdd is now in selections, it has been
    // and can be removed from selectionsToAdd
    commit(
      'setSelectionsToAdd',
      state.selectionsToAdd.filter(negate(_isInSelections(selections)))
    )
    // If a selection from selectionsToRemove is no longer in selections, it
    // has been deleted and can be removed from selectionsToRemove
    commit(
      'setSelectionsToRemove',
      state.selectionsToRemove.filter(_isInSelections(selections))
    )
  },
  updateIsLoading({ commit }, isLoading) {
    commit('setIsLoading', isLoading)
  },
  updateIsRefreshingPaymentMethods({ commit }, isRefreshing) {
    commit('setIsRefreshingPaymentMethods', isRefreshing)
  },
  handleClickSelectionFromSearch(
    { getters, dispatch },
    {
      company,
      /** Allows us to send MixPanel events from within this action */
      analyticsTrackFn = () => {}
    }
  ) {
    const existingSelection = getters.visibleSelections.find(
      _companyMatches(company)
    )
    if (existingSelection) {
      if (existingSelection.status === USER_COMPANY_SELECTION_STATUS.SELECTED) {
        analyticsTrackFn({
          event: `Deselected Company From ${PAGES.SEARCH_COMPANY}`,
          payload: { company: company.name }
        })
        dispatch('removeSelection', {
          selection: existingSelection,
          strategy: SELECTION_REMOVAL_STRATEGY.BATCH
        })
      } else {
        dispatch('openSelectionDetail', existingSelection)
      }
    } else {
      analyticsTrackFn({
        event: `Selected Company From ${PAGES.SEARCH_COMPANY}`,
        payload: { company: company.name }
      })
      dispatch('addSelection', company)
    }
  },
  openSelectionDetail({ dispatch }, selection) {
    dispatch(
      'modal/openModal',
      {
        view: MODAL_VIEW.PAYLINK_DETAIL,
        overlay: true,
        data: { selection }
      },
      { root: true }
    )
  },
  async saveSelections({ state }) {
    if (!state.selectionsToAdd.length && !state.selectionsToRemove.length)
      return

    await payLinkService.patchSelections({
      companyIdsToAdd: state.selectionsToAdd.map(
        (selection) => selection.company._id
      ),
      selectionIdsToRemove: state.selectionsToRemove.map(
        (selection) => selection._id
      )
    })
  },
  removeSelection(
    { state, commit },
    { selection, strategy = SELECTION_REMOVAL_STRATEGY.SINGLE }
  ) {
    const isSavedSelection = Boolean(selection._id)

    if (!isSavedSelection) {
      commit(
        'setSelectionsToAdd',
        state.selectionsToAdd.filter(negate(_companyMatches(selection.company)))
      )
      return
    }

    commit(
      'setSelections',
      state.selections.filter(negate(_companyMatches(selection.company)))
    )
    if (strategy === SELECTION_REMOVAL_STRATEGY.BATCH) {
      commit('setSelectionsToRemove', [...state.selectionsToRemove, selection])
    } else {
      payLinkService.patchSelections({
        selectionIdsToRemove: [selection._id]
      })
    }
  },
  updatePaymentMethodForConnector({
    state,
    rootState,
    getters,
    rootGetters,
    commit
  }) {
    const paymentMethod = determinePaymentMethodForConnector({
      cards: rootGetters['user/cardPaymentMethods'],
      accounts: rootState.user.userData.accounts,
      currentPaymentMethod: state.paymentMethod,
      supportedPaymentMethodTypes: getters.supportedPaymentMethodTypes
    })

    commit('setPaymentMethod', paymentMethod)
  }
})

function _isInSelections(selections) {
  return (selection) => selections.some(_companyMatches(selection.company))
}

function _companyMatches(company) {
  return (selection) => selection.company._id === company._id
}

export const createPayLinkModule = (payLinkService) => ({
  namespaced: true,
  state,
  getters,
  mutations,
  actions: createPayLinkActions(payLinkService)
})
