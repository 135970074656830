<template>
  <div
    :class="{
      'show-overlay': modalState.overlay && modalIsOpen,
      dark: useDarkMode
    }"
    @click="closeModal"
    @keyup.esc="closeModal"
    class="app-content-overlay"
  ></div>
  <div
    class="modal-wrap"
    :class="{
      dark: useDarkMode,
      'powered-by-atomic-enabled': poweredByAtomic,
      'full-page': !modalState.overlay,
      overlay: modalState.overlay
    }"
  >
    <div v-if="modalState.overlay" class="grabhandle"></div>
    <WalmartAuthenticationHelp
      v-if="currentView === $CONSTANTS.MODAL_VIEW.WALMART_AUTHENTICATION_HELP"
    />
    <ExitModal v-if="currentView === $CONSTANTS.MODAL_VIEW.EXIT" />
    <ErrorModal v-if="currentView === $CONSTANTS.MODAL_VIEW.ERROR" />
    <DistributionOptionsModal
      v-if="currentView === $CONSTANTS.MODAL_VIEW.DISTRIBUTION_OPTIONS"
    />
    <DistributionEditAmountModal
      v-if="currentView === $CONSTANTS.MODAL_VIEW.DISTRIBUTION_EDIT_AMOUNT"
    />
    <ProtectYouModal v-if="currentView === $CONSTANTS.MODAL_VIEW.PROTECT_YOU" />
    <LegalModal v-if="currentView === $CONSTANTS.MODAL_VIEW.LEGAL" />
    <OfflineModal v-if="currentView === $CONSTANTS.MODAL_VIEW.OFFLINE" />
    <PoweredByAtomic v-if="canShowPoweredByAtomic" />
    <UplinkDemoModal v-if="currentView === $CONSTANTS.MODAL_VIEW.UPLINK_DEMO" />
    <PayLinkSelectPaymentMethodModal
      v-if="
        currentView === $CONSTANTS.MODAL_VIEW.PAY_LINK_SELECT_PAYMENT_METHOD
      "
    />
    <PayLinkDetailModal
      v-if="currentView === $CONSTANTS.MODAL_VIEW.PAYLINK_DETAIL"
    />
    <AdpEddeSdkModal
      v-if="currentView === $CONSTANTS.MODAL_VIEW.ADP_EDDE_SDK"
    />
    <AndroidInstantApps
      v-if="currentView === $CONSTANTS.MODAL_VIEW.ANDROID_INSTANT_APPS"
    />
    <FindEmployerHelpModal
      v-if="currentView === $CONSTANTS.MODAL_VIEW.FIND_EMPLOYER_HELP"
    />
    <FindCredentialsHelpModal
      v-if="currentView === $CONSTANTS.MODAL_VIEW.FIND_CREDENTIALS_HELP"
    />
  </div>
</template>

<script>
import AdpEddeSdkModal from '@/components/Modal/AdpEddeSdkModal.vue'
import WalmartAuthenticationHelp from '@/components/Modal/WalmartAuthenticationHelp.vue'
import DistributionOptionsModal from '@/components/Modal/DistributionOptionsModal.vue'
import DistributionEditAmountModal from '@/components/Modal/DistributionEditAmountModal.vue'
import ErrorModal from '@/components/Modal/ErrorModal.vue'
import ExitModal from '@/components/Modal/ExitModal.vue'
import LegalModal from '@/components/Modal/LegalModal.vue'
import OfflineModal from '@/components/Modal/OfflineModal.vue'
import PoweredByAtomic from '@/components/Shared/PoweredByAtomic.vue'
import ProtectYouModal from '@/components/Modal/ProtectYouModal.vue'
import UplinkDemoModal from '@/components/Modal/UplinkDemoModal.vue'
import PayLinkDetailModal from '@/components/Modal/PayLinkDetailModal.vue'
import PayLinkSelectPaymentMethodModal from '@/components/Modal/PayLinkSelectPaymentMethodModal.vue'
import AndroidInstantApps from '@/components/Modal/AndroidInstantApps.vue'
import FindEmployerHelpModal from '@/components/Modal/FindEmployerHelpModal.vue'
import FindCredentialsHelpModal from '@/components/Modal/FindCredentialsHelpModal.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { store } from '@/store'
import { MODAL_ANIMATION_DURATION } from '@/util/animation'
import { customCSSIsEnabledAndUsed } from '@/util/theme'

export default {
  name: 'ModalWrap',
  components: {
    AdpEddeSdkModal,
    WalmartAuthenticationHelp,
    DistributionOptionsModal,
    DistributionEditAmountModal,
    ErrorModal,
    ExitModal,
    LegalModal,
    OfflineModal,
    PoweredByAtomic,
    ProtectYouModal,
    UplinkDemoModal,
    PayLinkDetailModal,
    PayLinkSelectPaymentMethodModal,
    AndroidInstantApps,
    FindEmployerHelpModal,
    FindCredentialsHelpModal
  },
  data() {
    return {
      openModalElement: null
    }
  },
  computed: {
    ...mapState('modal', {
      modalState: (state) => state.modalState,
      modalIsOpen: (state) => state.isOpen
    }),
    ...mapState('user', ['userData']),
    ...mapGetters('theme', ['useDarkMode', 'poweredByAtomic']),
    currentView() {
      if (this.modalState.view) {
        this.saveModalTriggerElementIdToStore()
      } else if (!this.modalState.view && this.modalIsOpen) {
        this.setFocusToModalTriggerElement()
      }

      return this.modalState.view
    },
    canShowPoweredByAtomic() {
      return (
        (this.poweredByAtomic ||
          customCSSIsEnabledAndUsed({ userData: this.userData })) &&
        this.modalIsOpen
      )
    }
  },
  methods: {
    ...mapActions('modal', ['closeModal']),
    saveModalTriggerElementIdToStore() {
      const allActiveElementIds = store.state.activeElement.activeElementHistory

      if (allActiveElementIds.length > 0) {
        store.commit(
          'activeElement/addModalTriggerElementId',
          allActiveElementIds.pop()
        )
      }
    },
    setFocusToModalTriggerElement() {
      const modalTriggerElementId =
        store.state.activeElement.modalTriggerElementId

      setTimeout(() => {
        try {
          document.getElementById(modalTriggerElementId).focus()
          store.dispatch('activeElement/clearModalTriggerElementId')
        } catch {
          document.getElementById('focusHeading')?.focus()
        }
      }, MODAL_ANIMATION_DURATION)
    }
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  height: 100%;
  top: 0;
}
.overlay {
  bottom: 0;
}

.app-content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: var(--gray-900);
  opacity: 0;
  z-index: 2;
  display: none;

  .dark & {
    background: var(--gray-1000);
  }
}

.show-overlay {
  display: block;
}

.modal-wrap {
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  width: 100%;
  transform: translate3d(0, 100%, 0);
  z-index: 11;
  will-change: transform, opacity;
  background: var(--white);

  &.dark {
    background: var(--gray-950);
  }

  &.powered-by-atomic-enabled {
    padding-bottom: var(--pbaHeight);
  }

  @media (min-width: 851px) {
    background: none;

    &.dark {
      background: none;
    }

    &.powered-by-atomic-enabled {
      background: var(--white);

      &.dark {
        background: var(--darkModeBg);
      }
    }
  }
}

.grabhandle {
  //ios style drawer slide indicator
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 4px;
  background: var(--gray-200);
  border-radius: 2px;
  z-index: 1;
  margin-top: 8px;
  margin-bottom: 8px;
  .dark & {
    background: var(--gray-700);
  }
}
</style>
