import { detail } from './detail'
import { searchCompany } from './search-company'
import { addCard } from './add-card'
import { shared } from './shared'
import { switchHome } from './switch-home'
import { switchWelcome } from './switch-welcome'

export const payLink = {
  detail,
  addCard,
  searchCompany,
  shared,
  switchHome,
  switchWelcome
}
