export const uplinkInterstitial = {
  title: ({ name }) => `Sign in directly on ${name}`,
  depositDescription:
    'Once signed in, you will be brought back here to finish setting up your direct deposit.',
  verifyDescription:
    'Once signed in, you will be brought back here to finish connecting your account.',
  continue: ({ name }) => `Continue on ${name}`,
  scanQRCode: 'Scan this QR code with the camera on your smartphone',
  cameraScanQR({ companyName }) {
    return `Scan QR code with device camera app to sign in to ${companyName}`
  },
  openTrueAuthDeposit: 'Open the TrueAuth tool to update your direct deposit',
  openTrueAuthTax: 'Open the TrueAuth tool to upload your tax return',
  openTrueAuthVerify({ companyName }) {
    return `Open the TrueAuth tool to connect directly to your ${companyName} account.`
  },
  qrCodeGenerationDeposit({ companyName }) {
    return `This will launch TrueAuth, a tool that updates your direct deposit directly from ${companyName}.`
  },
  qrCodeGenerationTax({ companyName }) {
    return `This will launch TrueAuth, a tool that helps you upload your tax return directly from ${companyName}.`
  },
  qrCodeGenerationFallback({ companyName }) {
    return `This will launch TrueAuth, a tool that connects directly to your ${companyName} account.`
  },
  trueAuthHelpsDeposit({ companyName }) {
    return `TrueAuth helps us update your direct deposit directly from ${companyName}.`
  },
  trueAuthHelpsTax({ companyName }) {
    return `TrueAuth helps us upload your tax return directly from ${companyName}.`
  },
  trueAuthHelpsVerify({ companyName }) {
    return `TrueAuth helps us connect your employment data directly from ${companyName}.`
  },
  notWorking: 'Not working?',
  openNow: 'Open Now',
  copyLink() {
    return IS_ANDROID
      ? 'Copy link and paste in Chrome'
      : 'Copy link and paste in Safari'
  },
  linkCopied: 'Link copied!',
  afterCopy: `After copying the URL, paste it into your device's web browser.`,
  openTrueAuth: 'Open TrueAuth',
  signInWithTrueAuth: 'Sign in with TrueAuth',
  signInWithQRCode: 'Sign in with QR Code',
  enterYourInfoManually: 'Enter your info manually',
  usingAndroid: 'If you are using an Android device, you may need to ',
  enableInstantApps: 'enable instant apps',
  turnOnGooglePlayInstant: 'How to turn on Google Play Instant',
  step1: 'Open the Google Play app.',
  step2: 'At the top right, tap the profile icon.',
  step3: 'Tap Settings > General > Google Play Instant.',
  step4: 'Turn Upgrade web links on or off.',
  tip: `Tip: If Google Play Instant isn't in your settings, it may not be available for your device type.`
}
